import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import styled, { css } from 'styled-components';
import palette from '../../lib/styles/palette';
import moment from 'moment';
import ActionTable from '../common/ActionTable';
import { 
    sort,
    stateNumberSort,
    selectMainMembershipState,
} from '../../lib/commonFunc/commonFunc';
import defaultProfile from '../../resources/images/common/default_user.png';
import { birthToAge, dateToString, timeValueToDate, timeValueToDate2 } from '../../lib/commonFunc/commonFunc';

const PhotoBlock = styled.div`
  border-radius: 50%;
  width: 12rem;
  height: 12rem;
  margin: 25px 25px;

  ${(props) =>
    props.profile || props.clubProfile
          ? (props.clubProfile ? css`
              background-image: url('https://healthnavi.s3.ap-northeast-2.amazonaws.com/ClubMemberProfile/${props.clubProfile}');
              background-size: cover;
              cursor: pointer;
            `
            :
            (props.profile.indexOf('http') == -1? css`
              background-image: url('https://healthnavi.s3.ap-northeast-2.amazonaws.com/UserProfile/${props.profile}');
              background-size: cover;
              cursor: pointer;
            ` : css`
              background-image: url('${props.profile}');
              background-size: cover;
              cursor: pointer;
            `))
          : css`
              background-image: url(${defaultProfile});
              background-size: cover;
            `}
`;
const UserInfoLabelBlock = styled.div`
  width: 6rem;
`;
const UserLabelBlock = styled.div`
  width: 16rem;
`;
const UserInfoLabel = styled.div`
    font-size: 13pt;
    padding: 5px 8px;
`;
const UserLabel = styled.div`
    font-size: 13pt;
    padding: 5px 0;
    color: #1A73E9;
`;

const ProfileBlock = styled.div`
  display: flex;
  align-items: center;
`;

const DdayBlock = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 45px;
`;
const DdayLabel = styled.div`
    font-size: 2rem;
`;
const DdayInfoLabel = styled.div`
    font-size: 16pt;
    padding: 8px;
`;

const ResearchBlock = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`;

const SearchListContainer = styled.div`
    ${(props) => props.height? css`height:${props.height}vh;`:css`height: 55vh;`}
`;

const MembershipTable = styled.div`
  width: 100%;
  text-align: center;
`
const MembershipTableHeader = styled.ul`
  display:flex;
  width: 100%;
  border-top: 1px solid ${palette.webGray[5]};
  border-bottom: 1px solid ${palette.webGray[5]};
  font-weight: bold;

  & li {
    padding: 14px 10px;

    &.title {
      width: 35%;
    }
    &.state {
      width: 10%;
    }
    &.registDay, &.count {
      width: 20%;
    }
    &.day {
      width: 35%;
    }
    &.price {
      width: 15%;
    }
    &.coach {
      width: 12%;
    }
    &.action {
      width: 8%;
    }
  }
`
const MembershipNullBox = styled.div`
  width: 100%;
  height: 200px;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const MembershipTableBody = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 200px;
    overflow: overlay;
    border-bottom: 1px solid ${palette.webGray[17]};

    &::-webkit-scrollbar {
        width: 10px;
    }
    &::-webkit-scrollbar-thumb {
        background-color: ${palette.webGray[4]};
        border-radius: 10px;
        background-clip: padding-box;
        border: 2px solid transparent;
    }
    &::-webkit-scrollbar-track {
        background-color: ${palette.webGray[2]};
        border-radius: 10px;
        box-shadow: inset 0px 0px 5px white;
    }
`;
const MembershipItem = styled.ul`
  width: 100%;
  display: flex;
  font-size: .95rem;
  border-top: 1px solid ${palette.webGray[17]};

  & :nth-child(1){
    border-top: none;
  }

  & .title, & .state, & .registDay, & .day,  & .startDay, & .endDay, & .count, & .price, & .coach, & .action {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 12px 10px;
  }

  & .title {
    width: 35%;
    color: ${palette.webCyan[5]};
    cursor: pointer;
  }
  & .state {
    width: 10%;
  }
  & .registDay, & .count {
    width: 20%;
  }
  & .day {
    width: 35%;
  }
  & .price {
    width: 15%;
  }
  & .coach {
    width: 12%;
  }
  & .action {
    position: relative;
    width: 8%;
  }
`;
const StateBox = styled.div`
  width: 100%;
  background-color: ${palette.valid};
  color: #fff;
  border-radius: 4px;
  padding: 5px  1px;
  text-overflow:ellipsis; 
  overflow:hidden; 
  white-space:nowrap;

  & span {
    font-size: .7rem;
  }

  &.expiration {
  background-color: ${palette.expired};
  }
  &.holding {
  background-color: ${palette.hold};
  }
  &.waiting {
  background-color: ${palette.wait};
  }
`

const AttendMemberInfo = ({clubLessonInfoData, pushMemberPage}) => {
    const {
        attendMember,
        attendMemberRes
    } = useSelector(({ attend }) => ({
        attendMember: attend.selectMember,
        attendMemberRes: attend.selectMemberRes,
    }));

    const getDdayString = ()=>{
        if(!attendMember || !attendMember.membership || !attendMember.membership.infos)
        {
            return ""
        }
        let ddaymax = -1;
        let today = moment().startOf('day').unix()
        let isReady = true;

        if(attendMember.membership.infos.length == 0)
        {
            isReady = false;
        }

        for (let i = 0; i < attendMember.membership.infos.length; i++) {
            let membership = attendMember.membership.infos[i];
            if((membership.type == 1 || membership.type == 3))
            {
                ddaymax = Math.max(Math.floor((membership.endTime - today) / 60 / 60 / 24), ddaymax);
            }
            if(membership.startTime <= today)
            {
                isReady = false;
            }
        }

        if(ddaymax == -1)
        {
            if(isReady)
            {
                return '회원권이 만료 되었습니다.';
            }
            else
            {
                return '회원권이 없습니다.'
            }
        }
        else
        {
            if(isReady)
            {
                return '회원권이 대기 중입니다.';
            }
            return `D-${ddaymax}일`
        }
    }

    const getDdayDateString = ()=>{
        if(!attendMember || !attendMember.membership || !attendMember.membership.infos)
        {
            return ""
        }
        let enddate = 0;
        let today = moment().startOf('day').unix()
        let isReady = false;

        for (let i = 0; i < attendMember.membership.infos.length; i++) {
            let membership = attendMember.membership.infos[i];
            if((membership.type == 1 || membership.type == 3))
            {
                enddate = Math.max(membership.endTime, enddate);
            }
            else if(membership.endTime >= today)
            {
                enddate = Math.max(membership.endTime, enddate);
            }
        }

        if(enddate == 0)
        {
            return ' ';
        }
        else
        {
            return `(${dateToString(enddate, true)} 까지)`
        }
    }
    
    const classificationMambershipState = (state) => {
        switch(state) {
        case "유효" : return <StateBox>유효</StateBox> ;
        case "만료" : return <StateBox className="expiration">만료</StateBox> ;
        case "홀드" : return <StateBox className="holding">홀드</StateBox> ;
        case "대기" : return <StateBox className="waiting">대기</StateBox> ;
        }
    }

    const classificationMambershipStateNumber = (state) => {
        switch(state) {
        case "유효" : return 1 ;
        case "만료" : return 2 ;
        case "홀드" : return 4 ;
        case "대기" : return 3 ;
        }
    }
    
  return (
    <>
        <SearchListContainer>
            {
                attendMember&&
                <ProfileBlock>
                    <PhotoBlock profile={attendMember && attendMember.user && attendMember.user.info && attendMember.user.info.profileURL}/>
                    <UserInfoLabelBlock>
                        <UserInfoLabel>계정</UserInfoLabel>
                        <UserInfoLabel>이름</UserInfoLabel>
                        <UserInfoLabel>나이</UserInfoLabel>
                        <UserInfoLabel>성별</UserInfoLabel>
                        <UserInfoLabel>연락처</UserInfoLabel>
                        <UserInfoLabel>락커</UserInfoLabel>
                        <UserInfoLabel>가입일</UserInfoLabel>
                    </UserInfoLabelBlock>
                    <UserLabelBlock>
                        <UserLabel>{attendMember && attendMember.user && attendMember.user.info ? attendMember.user.info.account:''}</UserLabel>
                        <UserLabel style={{color: palette.webCyan[5], cursor: 'pointer'}} onClick={() => pushMemberPage(attendMember.user.info.playerID)}>{attendMember && attendMember.user && attendMember.user.info ? attendMember.user.info.name:''}</UserLabel>
                        <UserLabel>{attendMember && attendMember.user && attendMember.user.info ? birthToAge(attendMember.user.info.birth):''}</UserLabel>
                        <UserLabel>{attendMember && attendMember.user && attendMember.user.info ? (attendMember.user.info.sex == 1?'남자':'여자'):''}</UserLabel>
                        <UserLabel>{attendMember && attendMember.user && attendMember.user.info ? attendMember.user.info.phone:''}</UserLabel>
                        <UserLabel>{(attendMember && attendMember.locker && attendMember.locker.infos && attendMember.locker.infos.length > 0 && attendMember.locker.infos.filter(item => {return item.endTime >= Math.ceil(new Date().setHours(0, 0, 0, 0) / 1000)}) && attendMember.locker.infos.filter(item => {return item.endTime >= Math.ceil(new Date().setHours(0, 0, 0, 0) / 1000)}).length > 0)? sort(attendMember.locker.infos.filter(item => {return item.endTime >= Math.ceil(new Date().setHours(0, 0, 0, 0) / 1000)}), 'endTime')[0].name + ' - ' + sort(attendMember.locker.infos.filter(item => {return item.endTime >= Math.ceil(new Date().setHours(0, 0, 0, 0) / 1000)}), 'endTime')[0].lockerSN:'없음'}</UserLabel>
                        <UserLabel>{attendMember && attendMember.user && attendMember.user.info ? timeValueToDate(attendMember.user.info.registTime):''}</UserLabel>
                    </UserLabelBlock>
                </ProfileBlock>
            }
            {
                attendMember&&
                <DdayBlock>
                    <DdayLabel>{getDdayString()}</DdayLabel>
                    <DdayInfoLabel>{getDdayDateString()}</DdayInfoLabel>
                </DdayBlock>
            }
            {
                attendMember&&
                <MembershipTable>
                  <MembershipTableHeader>
                    <li className="title" >상품명</li>
                    <li className="state" >상태</li>
                    <li className="day" >기간</li>
                    <li className="count" >횟수</li>
                  </MembershipTableHeader>
                  <MembershipTableBody>
                    { attendMember && attendMember.membership && attendMember.membership.infos.length > 0 ? stateNumberSort(sort(attendMember.membership.infos, 'registTime').map(item => {return {...item, state: classificationMambershipStateNumber(selectMainMembershipState(item, clubLessonInfoData))}}), 'state').map((membership, idx) => {
                      return(
                        <div key={idx}>
                          <MembershipItem>
                            <li className="title">{membership.title}</li>
                            <li className="state" >{membership.refundPrice > 0 ? <StateBox className="expiration">만료<span>(환불)</span></StateBox> : classificationMambershipState(selectMainMembershipState(membership, clubLessonInfoData))}</li>
                            <li className="day" >{membership.type != 2 ? `${timeValueToDate(membership.startTime+3600)} ~ ${timeValueToDate(membership.endTime)}` : "-"}</li>
                            <li className="count" >{membership.totalCount == 9999 ? "-" : clubLessonInfoData && clubLessonInfoData.type !== 0 && membership.lessonType !== 0? `${membership.currentCount}회 / ${membership.totalCount}회` : `${membership.totalCount}회`}</li>
                          </MembershipItem>
                        </div>
                      )
                    }) : <MembershipNullBox>회원권 구매 기록이 없습니다.</MembershipNullBox> }
                  </MembershipTableBody>
                </MembershipTable>
            }
        </SearchListContainer>
    </>
  );
};

export default AttendMemberInfo;
