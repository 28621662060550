import React, { useEffect, useState, useCallback } from 'react';
import Member from '../../../components/member/Member';
import { withRouter } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import {
  setItem,
  initialize,
  changeField,
  updateMembership,
} from '../../../modules/member/sale';
import {
  removeMember,
  initMember,
  changeProfile,
} from '../../../modules/member/member';
import {
  removeMembership,
  readMember,
  loadUserMembership,
  readMember_Membership,
  readMember_Subdata,
  initializeRemoveRes,
} from '../../../modules/member/membership';
import {
  changeHoldField,
  holdMembership,
} from '../../../modules/member/hold';
import {
  memberStateResolver,
  inputNumberRemoveComma,
  uploadImageToServer,
} from '../../../lib/commonFunc/commonFunc';
import { readPointUser } from '../../../modules/member/point';
import { cancelHolding } from '../../../lib/api/membership';
import {
  setModifyMemoField,
  changeMemoField,
  initializeModifyMemoField,
  deleteMemo,
  initializeMemoForm,
  changeModifyMemoField,
  registMemo,
  loadList,
  modifyMemo,
  initRes,
  init_MemoList,
} from '../../../modules/member/memo';
import {
  searchUserAttend,
  initAttend_res,
} from '../../../modules/attend/attend';
import dotenv from 'dotenv'
dotenv.config()

const MemberContainer = ({ match, history }) => {
  const { playerID } = match.params;
  const dispatch = useDispatch();

  const {
    member,
    club,
    locker,
    memberships,
    clubMembershipID,
    selectedItem,
    holdForm,
    memoForm,
    loading,
    pointLoading,
    socketKey,
    saleResult,
    updateRes,
    targetID,
    holdResult,
    removeRes,
    pointUser,
    memoList,
    isModify,
    prevMemoList,
    modifyForm,
    changeProfileRes,
    refundRes,
    membershipRemoveRes,
    prevMemoRes,
    user,
    error,
    coaches,
    userAttendLogs,
    clubLessonInfoData,
    registRes,
    deleteRes,
    modifyRes,
    memberLoadRes,
    memolistLoadRes,
    userAttendLogsRes,
  } = useSelector(
    ({ member, club, memo, sale, hold, loading, attend, clubLessonInfo, socket, membership, refund, user, pointUser }) => ({
      pointUser: pointUser,
      member: membership.member,
      memberLoadRes:membership.memberLoadRes,
      memberInfo: membership,
      targetID: member.playerID,
      club: club.defaultClub,
      coaches: membership.coach,
      memberships: membership.membership,
      locker: membership.locker,
      clubMembershipID: sale.originalclubMembershipID,
      selectedItem: sale,
      prevMemoList: memo.prevMemoList,
      memoForm: memo.memoForm,
      registRes: memo.registRes,
      deleteRes: memo.deleteRes,
      modifyRes: memo.modifyRes,
      prevMemoRes: memo.prevMemoRes,
      isModify: memo.isModify,
      refundRes: refund.result,
      modifyForm: memo.modifyForm,
      holdForm: hold,
      holdResult: hold.result,
      updateRes: sale.updateRes,
      saleResult: sale.result,
      memoList: memo.memoList,
      loading: loading['membership/READ_MEMBER'],
      pointLoading: loading['point/READ_POINTUSER'],
      socketKey: socket.socketKey,
      user: user.user,
      membershipRemoveRes: membership.removeRes,
      removeRes: member.removeRes,
      changeProfileRes: member.changeProfileRes,
      error: member.error,
      memolistLoadRes: memo.listLoadRes,
      clubLessonInfoData: clubLessonInfo.clubLessonInfoData,
      userAttendLogs: attend.userAttendLogs,
      userAttendLogsRes: attend.userAttendLogsRes,
    }),
  );
  const [modals, setModals] = useState([
    {
      id: 'modal',
      visible: false,
    },
    {
      id: 'hold',
      visible: false,
    },
    {
      id: 'modify',
      visible: false,
    },
    {
      id: 'remove',
      visible: false,
    },
    {
      id: 'sale',
      visible: false,
    },
    {
      id: 'refund',
      visible: false,
    },
    {
      id: 'cancelHolding',
      visible: false,
    },
  ]);
  const [purchasHeaderState, setPurchasHeaderState] = useState('회원권');
  const [openMore, setOpenMore] = useState(-1);
  const viewOpenMore = (idx) => {
    if (idx === openMore) {
      setOpenMore(-1);
      return;
    }
    setOpenMore(idx);
  };
  const setItemInfo = (item) => {
    dispatch(setItem(item));
  };
  const onCloseModal = useCallback(
    (id) => {
      //if (id === 'sale') {
      //  dispatch(initialize());
      //}

      setModals((modals) =>
        modals.map((modal) =>
          modal.id === id ? { ...modal, visible: false } : modal,
        ),
      );
    },
    [dispatch],
  );
  
  const onRemoveMember = () => {
    if(coaches.infos.map(item => {return Number(item.playerID)}).includes(Number(playerID))) {
      alert("클럽 탈퇴를 하려면 먼저 강사 삭제를 해주세요.")
      return
    } else {
      dispatch(removeMember({
        clubID: club.clubID,
        targetID: playerID,
        socketKey,
      }));
    }
  }

  const onChangeProfileMember = (clubProfileURL) => {
    if(member) {
      dispatch(changeProfile({
        targetID: playerID,
        socketKey,
        clubProfileURL,
      }))
    }
  }

  
  const setProfileImage = async (img) => {

    return;
    
    //--> delete
    // var AWS = require('aws-sdk');
    // var s3 = new AWS.S3({accessKeyId: process.env, secretAccessKey: process.env.REACT_APP_AWS_ACCESS_KEY, region:'ap-northeast-2'});
    
    // if(member && member.clubProfileURL != "" && member.clubProfileURL.indexOf('http') == -1)
    // {
    //     var params = {
    //         Bucket: 'healthnavi',
    //         Delete: { // required
    //             Objects: [ // required
    //                 {
    //                     Key: 'ClubMemberProfile/'+member.clubProfileURL
    //                 },
    //             ],
    //         },
    //     };

    //     s3.deleteObjects(params, function (err, data) {
    //         if (err) console.log(err, err.stack); // an error occurred
    //         else console.log(data);           // successful response
    //     });
    // }

    let _clubProfileURL = member.playerID + "_" + Math.floor(Date.now() / 1000).toString() + '.jpg';

    let result = await uploadImageToServer(img, _clubProfileURL, 'clubdata/notification', img.type);
    
    onChangeProfileMember(_clubProfileURL);
    return;

    // let _clubProfileURL = member.playerID + "_" + Math.floor(Date.now() / 1000).toString() + '.jpg';

    // //--> upload

    // //let contentType = 'image/jpeg';
    // let contentDeposition = 'inline;filename="' + _clubProfileURL + '"';
    // //const base64 = await RNFS.readFile(imagePath, 'base64');
    // const base64Data = new Buffer.from(img.replace(/^data:image\/\w+;base64,/, ""), 'base64');
    // const type = img.split(';')[0].split('/')[1];
    // //const arrayBuffer = decode(imagePath);

    // const paramsupload = {
    //     Bucket: "healthnavi",
    //     Key: 'ClubMemberProfile/'+_clubProfileURL,
    //     ACL:'public-read',
    //     Body: base64Data,
    //     ContentDisposition: contentDeposition,
    //     ContentType: type,
    // };
    // let uploadresult = await new Promise((resolve, reject) => {
    //     s3.upload(paramsupload, (err, data) => {
    //         if (err) {
    //             reject(err);
    //         }
    //         resolve(data);
    //     });
    // });

    // onChangeProfileMember(_clubProfileURL);
  }

  const memoRegist = () => {
    if ( memoForm == "") {
      alert("메모를 입력해주세요.")
      return
    }
    if (playerID && club) {
      dispatch(
        registMemo({
          clubID: club.clubID,
          targetID: playerID,
          clubMemo: {
            memoID: "",
            playerID: Number(playerID),
            registTime: Math.ceil(new Date().getTime() / 1000),
            content: memoForm,
          },
          socketKey,
        }),
      );
      dispatch(initRes());
      dispatch(
        loadList({
          clubID: club.clubID,
          socketKey,
          targetID: playerID,
        }),
      );
      dispatch(initializeMemoForm());
    }
  };

  const memoDelete = (memoID) => {
    if (playerID && club && memoID) {
      dispatch(
        deleteMemo({
          clubID: club.clubID,
          targetID: playerID,
          memoID,
          socketKey,
        })
      )
    }
    dispatch(initRes());
    dispatch(
      loadList({
        clubID: club.clubID,
        socketKey,
        targetID: playerID,
      }),
    );
  };

  const memoModify = () => {
    if(modifyForm) {
      dispatch(
        modifyMemo({
          clubID: club.clubID,
          targetID: playerID,
          memoID: modifyForm.memoID,
          registTime: Math.ceil(new Date().getTime() / 1000),
          content: modifyForm.content,
          socketKey,
        })
      )
    }
    dispatch(initRes());
    dispatch(
      loadList({
        clubID: club.clubID,
        socketKey,
        targetID: playerID,
      }),
    );
  }

  const onChangeModifyMemoField = (e) => {
    const { value } = e.target;
    dispatch(changeModifyMemoField(value));
  }

  const setMemoModifyField = (memo) => {
    dispatch(setModifyMemoField({
      memoID: memo.memoID, 
      content: memo.content,
    }));
  }

  const initModifyMemoField = () => {
    dispatch(initializeModifyMemoField());
  }

  const memoFieldChange = (e) => {
    const { value } = e.target;
    dispatch(changeMemoField(value));
  };

  const onCloseAllModal = useCallback(
    () => {
      setModals((modals) =>
        modals.map((modal) =>
          true ? { ...modal, visible: false } : modal,
        ),
      );
    },
    [dispatch],
  );
  const onOpenModal = useCallback((id) => {

    if(id == 'sale')
    {
        dispatch(
            readPointUser({
            clubID: club.clubID,
            targetID: targetID,
            socketKey,
          }),
        );
    }
    setModals((modals) =>
      modals.map((modal) =>
        modal.id === id ? { ...modal, visible: true } : modal,
      ),
    );
  }, []);

  const onModify = () => {
    dispatch(
        readPointUser({
        clubID: club.clubID,
        targetID: targetID,
        socketKey,
      }),
    );
    setModals((modals) =>
      modals.map((modal) =>
        modal.id === 'sale' ? { ...modal, visible: true } : modal,
      ),
    );
  };

  const onConfirm = () => {
    dispatch(
      removeMembership({
        clubMembershipID,
        clubID: club.clubID,
        targetID,
        socketKey,
      }),
    );
    setModals((modals) =>
      modals.map((modal) =>
        modal.id === 'remove' ? { ...modal, visible: false } : modal,
      ),
    );
  };

  const onCancel = () => {
    setModals((modals) =>
      modals.map((modal) =>
        modal.id === 'remove' ? { ...modal, visible: false } : modal,
      ),
    );
  };
  const onCloseMoreBtn = () => {
    dispatch(initialize());
  };

  const onChange = (date, name) => {
    const value = moment(date).format('YYYY-MM-DD');
    dispatch(
      changeHoldField({
        key: name,
        value,
      }),
    );
  };
  const onChangeSaleField = (e) => {
    const { name, value } = e.target;
    dispatch(
      changeField({
        key: name,
        value,
      }),
    );
  };
  const onChangeHoldField = (e) => {
    const { name, value } = e.target;
    dispatch(
      changeHoldField({
        key: name,
        value,
      }),
    );
  };
  const chnagePurchasHeaderState = () => {
    if(purchasHeaderState == '회원권') {
      setPurchasHeaderState('구매 상품')
    } else {
      setPurchasHeaderState('회원권')
    }
  }

  const onHold = () => {
    if (member) {
      if (selectedItem.type === 2) {
        alert('횟수제 상품은 홀드할 수 없습니다');
        return false;
      }
      if (moment(holdForm.startTime).unix() > moment(holdForm.endTime).unix()) {
        alert('올바른 기간을 입력해주세요');
        return false;
      } else if (
        moment(holdForm.startTime).unix() === moment(holdForm.endTime).unix()
      ) {
        alert('최소 1일 이상의 홀드기간이필요합니다');
        return false;
      }
      if (
        moment(holdForm.startTime).unix() <
        moment(selectedItem.startTime).unix()
      ) {
        alert('홀드 시작일은 상품 시작일 이전일 수 없습니다');
        return false;
      }

      const membershipData = _handleHoldRequest.hold();

      dispatch(
        holdMembership({
          clubID: club.clubID,
          membership: membershipData,
          socketKey,
        }),
      );
    }
  };
  
  const onCancelHolding = async () => {
    const targetHolding = selectedItem.holdList.find(
      (t) => t.endTime > moment().unix(),
    );

    const subtractDay =
      moment().isAfter(moment(targetHolding.startTime * 1000)) &&
      moment().isBefore(moment(targetHolding.endTime * 1000))
        ? Math.ceil(
            moment
              .duration(
                moment(targetHolding.endTime * 1000).diff(
                  moment().startOf('day'),
                ),
              )
              .asDays(),
          ) + 1
        : Math.ceil(
            moment
              .duration(
                moment(targetHolding.endTime * 1000).diff(
                  moment(targetHolding.startTime * 1000),
                ),
              )
              .asDays(),
          ) + 1;

    const updateMembership = {
      card: selectedItem.card,
      cash: selectedItem.cash,
      pay: selectedItem.pay,
      point: selectedItem.point,
      category: selectedItem.category,
      clubItemID: selectedItem.clubItemID,
      clubMembershipID: selectedItem.clubMembershipID,
      coaches: selectedItem.coaches,
      currentCount: selectedItem.currentCount,
      playerID: selectedItem.playerID,
      playerName: selectedItem.playerName,
      price: selectedItem.price,
      registTime: moment(selectedItem.registTime).unix(),
      salePrice: selectedItem.salePrice,
      startTime: moment(selectedItem.startTime).unix(),
      title: selectedItem.title,
      totalCount: selectedItem.totalCount,
      isNew: selectedItem.isNew,
      unpayment: selectedItem.unpaymnet,
      type: selectedItem.type,
      state: 1,
      endTime: moment(selectedItem.endTime).subtract(subtractDay, 'd').unix(),
      holdList: [],
    };

    const res = await cancelHolding({
      clubID: club.clubID,
      membership: updateMembership,
      socketKey,
    });
    onCloseModal('cancelHolding');

    if (res.data && res.data.result === 1) alert('홀드가 해제되었습니다. ');
    else {
      alert('요청이 실패하였습니다. 로그아웃 후 다시 시도해 주세요');
      return false;
    }
    if (club) {
      dispatch(
        loadUserMembership({
          clubID: club.clubID,
          targetID: member.playerID,
          socketKey,
        }),
      );
    }
  };
  const onRefund = () => {
    if (
      Number(inputNumberRemoveComma(selectedItem.refund)) >
      Number(selectedItem.price)
    ) {
      alert('환불금액은 판매금액보다 클 수 없습니다');
      return false;
    }
    const willRefundMembership = {
      ...selectedItem,
      price:
        Number(selectedItem.price) -
        Number(inputNumberRemoveComma(selectedItem.refund)),
      state: 2,
      endTime: moment().startOf('day').subtract('d', 1).unix(),
    };

    const clubMembershipData = {
        clubMembershipID: willRefundMembership.clubMembershipID,
        clubItemID: willRefundMembership.clubItemID,
        card: willRefundMembership.card,
        card2: willRefundMembership.card2,
        company: willRefundMembership.company,
        company2: willRefundMembership.company2,
        authNumber: willRefundMembership.authNumber,
        authNumber2: willRefundMembership.authNumber2,
        InstallmentMonth: willRefundMembership.InstallmentMonth,
        InstallmentMonth2: willRefundMembership.InstallmentMonth2,
        cash: willRefundMembership.cash,
        point: willRefundMembership.point,
        pay: willRefundMembership.pay,
        salePrice: willRefundMembership.salePrice,
        price: willRefundMembership.price,
        category: willRefundMembership.category,
        state: willRefundMembership.state,
        holdList: willRefundMembership.holdList,
        controlList: willRefundMembership.controlList,
        coaches: willRefundMembership.coaches,
        playerID: willRefundMembership.playerID,
        playerName: willRefundMembership.playerName,
        title: willRefundMembership.title,
        type: willRefundMembership.type,
        startTime: moment(willRefundMembership.startTime).unix(),
        endTime: willRefundMembership.endTime,
        totalCount: willRefundMembership.totalCount,
        currentCount: willRefundMembership.currentCount,
        registTime: moment(willRefundMembership.registTime).unix(),
        unpayment: willRefundMembership.unpayment,
        isNew: false,
        ptID: willRefundMembership.ptID,
        totalCancelCount: willRefundMembership.totalCancelCount,
        lessonTime: willRefundMembership.lessonTime,
        lessonType: willRefundMembership.lessonType,
        countLinkFlag: willRefundMembership.countLinkFlag,
        paymentID: "",
        subscriptionID: willRefundMembership.subscriptionID,
      };

    dispatch(
      updateMembership({
        clubMembershipData,
        clubID: club.clubID,
        socketKey,
      }),
    );
    onCloseModal('refund');
  };

  const _handleHoldRequest = {
    hold: function () {
      const holdingID =
        selectedItem.holdList.reduce((previous, current) => {
          return previous.holdingID > current.holdingID
            ? previous.holdingID
            : current.holdingID;
        }, 0) + 1;
      const addDay =
        Math.ceil(
          moment
            .duration(
              moment(holdForm.endTime).diff(
                moment(holdForm.startTime).startOf('day'),
              ),
            )
            .asDays(),
        ) + 1;

      const holdingMembership = {
        clubMembershipID: selectedItem.clubMembershipID,
        state: 4,
        startTime: moment(selectedItem.startTime).unix(),
        endTime: moment(selectedItem.endTime).add(addDay, 'd').unix(),
        registTime: moment(selectedItem.registTime).unix(),
        playerID: member.playerID,
        playerName: member.name,
        title: selectedItem.title,
        totalCount: selectedItem.totalCount,
        currentCount: selectedItem.currentCount,
        type: selectedItem.type,
        category: selectedItem.category,
        card: selectedItem.card,
        cash: selectedItem.cash,
        point: selectedItem.point,
        pay: selectedItem.pay,
        unpayment: selectedItem.unpayment,
        salePrice: selectedItem.salePrice,
        price: selectedItem.price,
        isNew: selectedItem.isNew,
        clubItemID: selectedItem.clubItemID,
        coaches: selectedItem.coaches,
        ptID: selectedItem.ptID,
        paymentID: "",
        totalCancelCount: selectedItem.totalCancelCount,
        lessonTime: selectedItem.lessonTime,
        lessonType: selectedItem.lessonType,
        countLinkFlag: selectedItem.countLinkFlag,
        holdList: selectedItem.holdList.concat({
          holdingID,
          startTime: moment(holdForm.startTime).unix(),
          endTime: moment(holdForm.endTime).unix(),
          reason: holdForm.reason,
        }),
      };

      return holdingMembership;
    },
  };
  useEffect(() => {
    if (club) {
        dispatch(initMember());
        dispatch(init_MemoList());
        
        console.log('readMember!!')
        dispatch(initializeRemoveRes());
        dispatch(
            readMember({
            clubID: club.clubID,
            targetID: playerID,
            socketKey,
            }),
        );
    }
  }, [club, dispatch, playerID, socketKey]);

  useEffect(() => {
    if(memberLoadRes && memberLoadRes === 1) {
        console.log('readMember_Membership readMember_Subdata!! : ', member)
        dispatch(
            readMember_Membership({
            clubID: club.clubID,
            targetID: playerID,
            socketKey,
          }),
        );
        dispatch(
            readMember_Subdata({
            clubID: club.clubID,
            targetID: playerID,
            socketKey,
          }),
        );
        dispatch(initializeMemoForm());
        dispatch(initRes())
    }
  }, [memberLoadRes]);
  
  useEffect(() => {
    if(registRes && registRes === 1) {
      dispatch(
        loadList({
          clubID: club.clubID,
          socketKey,
          targetID: playerID,
        }),
      );
      dispatch(initializeMemoForm());
      dispatch(initRes())
    }
  }, [registRes])

  useEffect(() => {
    if(deleteRes && deleteRes === 1) {
      dispatch(
        loadList({
          clubID: club.clubID,
          socketKey,
          targetID: playerID,
        }),
      );
      dispatch(initializeMemoForm());
      dispatch(initRes())
    }
  }, [deleteRes])

  useEffect(() => {
    if(modifyRes && modifyRes === 1) {
      dispatch(
        loadList({
          clubID: club.clubID,
          socketKey,
          targetID: playerID,
        }),
      );
      dispatch(initializeMemoForm());
      dispatch(initRes())
    }
  }, [modifyRes])

  const stateConverter = (memberships) => {
    if (memberships && memberships.infos) {
      return memberStateResolver(
        memberships.infos.map((membership) => membership.state),
      );
    }
  };
  
//   useEffect(() => {
//     if(modifyRes && modifyRes === 1) {
//       dispatch(
//         loadList({
//           clubID: club.clubID,
//           socketKey,
//           targetID: playerID,
//         }),
//       );
//       dispatch(initializeMemoForm());
//       dispatch(initRes())
//     }
//   }, [modifyRes])

  useEffect(() => {
    if (club && member) {
        console.log('readPointUser ')
      dispatch(
          readPointUser({
          clubID: club.clubID,
          targetID: member.playerID,
          socketKey,
        }),
      );
    }
  }, [club, dispatch, socketKey, member]);
  
  useEffect(() => {
    if (club && member) {
        console.log('loadList ')
        dispatch(initRes());
      dispatch(
        loadList({
          clubID: club.clubID,
          socketKey,
          targetID: member.playerID,
        }),
      );
      console.log('searchUserAttend ')
      dispatch(initAttend_res());
      dispatch(searchUserAttend({
        clubID: club.clubID,
        playerID: member.playerID,
        socketKey,
      }))
    }
  }, [club, dispatch, socketKey, member, prevMemoList]);
  
  useEffect(() => {
    if (club && removeRes) {
      if(removeRes == 1) {
        alert("선택한 회원을 삭제하였습니다.")
        dispatch(initMember())
        history.push('/member');
      }
    }
  }, [removeRes]);
  
  useEffect(() => {
    if (club && changeProfileRes) {
      if(changeProfileRes == 1) {
        alert("회원 사진을 변경하였습니다.")
        dispatch(initMember())
        dispatch(initializeRemoveRes());
        dispatch(
          readMember({
            clubID: club.clubID,
            targetID: playerID,
            socketKey,
          }),
        );
      }
    }
  }, [changeProfileRes]);
  
  useEffect(() => {
    if (club && membershipRemoveRes && membershipRemoveRes.result) {
      if(membershipRemoveRes.result == 1){
        dispatch(initializeRemoveRes());
        dispatch(
          readMember({
            clubID: club.clubID,
            targetID: playerID,
            socketKey,
          }),
        );
      }
    }
  }, [membershipRemoveRes]);
  
  useEffect(() => {
    if (club && saleResult && saleResult.result) {
        console.log('saleResult.result : ', saleResult.result)
      if(saleResult.result == 1){
        dispatch(initializeRemoveRes());
        dispatch(
          readMember({
            clubID: club.clubID,
            targetID: playerID,
            socketKey,
          }),
        );
      }
    }
  }, [saleResult]);
  
  useEffect(() => {
    if (club && refundRes) {
      if(refundRes == 1){
        dispatch(initializeRemoveRes());
        dispatch(
          readMember({
            clubID: club.clubID,
            targetID: playerID,
            socketKey,
          }),
        );
      }
    }
  }, [refundRes]);
  
  useEffect(() => {
    if (club && updateRes && updateRes.result) {
      if(updateRes.result == 1){
        dispatch(initializeRemoveRes());
        dispatch(
          readMember({
            clubID: club.clubID,
            targetID: playerID,
            socketKey,
          }),
        );
      }
    }
  }, [updateRes]);

  return (
    <>
      <Member
        member={member}
        memberships={memberships}
        memberState={stateConverter(memberships)}
        onOpenModal={onOpenModal}
        onCloseModal={onCloseModal}
        onConfirm={onConfirm}
        onCancel={onCancel}
        modals={modals}
        viewOpenMore={viewOpenMore}
        openMore={openMore}
        setItemInfo={setItemInfo}
        onModify={onModify}
        selectedItem={selectedItem}
        onChangeHoldField={onChangeHoldField}
        holdForm={holdForm}
        locker={locker}
        onHold={onHold}
        loading={loading}
        onCloseMoreBtn={onCloseMoreBtn}
        onChange={onChange}
        onChangeSaleField={onChangeSaleField}
        onRefund={onRefund}
        onCancelHolding={onCancelHolding}
        purchasHeaderState={purchasHeaderState}
        chnagePurchasHeaderState={chnagePurchasHeaderState}
        pointUser={pointUser}
        memoList={memoList}
        memoFieldChange={memoFieldChange}
        memoRegist={memoRegist}
        memoDelete={memoDelete}
        memoForm={memoForm}
        pointLoading={pointLoading}
        setMemoModifyField={setMemoModifyField}
        isModify={isModify}
        modifyForm={modifyForm}
        initModifyMemoField={initModifyMemoField}
        onChangeModifyMemoField={onChangeModifyMemoField}
        memoModify={memoModify}
        onRemoveMember={onRemoveMember}
        setProfileImage={setProfileImage}
        clubLessonInfoData={clubLessonInfoData}
        userAttendLogs={userAttendLogs}
        memolistLoadRes={memolistLoadRes}
        userAttendLogsRes={userAttendLogsRes}
      />
    </>
  );
};

export default withRouter(MemberContainer);