import { handleActions, createAction } from 'redux-actions';
import createRequestSaga, {
  createRequestActionTypes,
} from '../../lib/createRequestSaga';
import * as membershipAPI from '../../lib/api/membership';
import * as memoAPI from '../../lib/api/memo';
import * as itemAPI from '../../lib/api/item';
import * as memberAPI from '../../lib/api/member';

import { takeLatest } from 'redux-saga/effects';
import { sortReverse } from '../../lib/commonFunc/commonFunc';
const [
  READ_MEMBER,
  READ_MEMBER_SUCCESS,
  READ_MEMBER_FAILURE,
] = createRequestActionTypes('membership/READ_MEMBER');

//--> 맴버쉽 따로 불러오기
const [
    READ_MEMBER_MEMBERSHIP,
    READ_MEMBER_MEMBERSHIP_SUCCESS,
    READ_MEMBER_MEMBERSHIP_FAILURE,
  ] = createRequestActionTypes('membership/READ_MEMBER_MEMBERSHIP');


//--> 서브데이터 따로 불러오기
const [
    READ_MEMBER_SUBDATA,
    READ_MEMBER_SUBDATA_SUCCESS,
    READ_MEMBER_SUBDATA_FAILURE,
  ] = createRequestActionTypes('membership/READ_MEMBER_SUBDATA');

const [
  REMOVE_MEMBERSHIP,
  REMOVE_MEMBERSHIP_SUCCESS,
  REMOVE_MEMBERSHIP_FAILURE,
] = createRequestActionTypes('membership/REMOVE_MEMBERSHIP');

const [
  LOAD_CLUBMEMBERSHIP,
  LOAD_CLUBMEMBERSHIP_SUCCESS,
  LOAD_CLUBMEMBERSHIP_FAILURE,
] = createRequestActionTypes('membership/LOAD_CLUBMEMBERSHIP');

const [
  LOAD_USER_MEMBERSHIP,
  LOAD_USER_MEMBERSHIP_SUCCESS,
  LOAD_USER_MEMBERSHIP_FAILURE,
] = createRequestActionTypes('membership/LOAD_USER_MEMBERSHIP');

const [
  LOAD_USER_PAYMENT,
  LOAD_USER_PAYMENT_SUCCESS,
  LOAD_USER_PAYMENT_FAILURE,
] = createRequestActionTypes('membership/LOAD_USER_PAYMENT');

const [
  READ_MEMO,
  READ_MEMO_SUCCESS,
  READ_MEMO_FAILURE,
] = createRequestActionTypes('membership/READ_MEMO');

const [
  LIST_ITEMS,
  LIST_ITEMS_SUCCESS,
  LIST_ITEMS_FAILURE,
] = createRequestActionTypes('item/LIST_ITEMS');

const INITIALIZE_RES = 'membership/INITIALIZE_RES';

export const readMember = createAction(
  READ_MEMBER,
  ({ clubID, targetID, socketKey }) => ({
    clubID,
    targetID,
    socketKey,
  }),
);

export const readMember_Membership = createAction(
    READ_MEMBER_MEMBERSHIP,
    ({ clubID, targetID, socketKey }) => ({
      clubID,
      targetID,
      socketKey,
    }),
  );

export const readMember_Subdata = createAction(
    READ_MEMBER_SUBDATA,
    ({ clubID, targetID, socketKey }) => ({
      clubID,
      targetID,
      socketKey,
    }),
  );

export const initializeRemoveRes = createAction(INITIALIZE_RES);

export const listItems = createAction(LIST_ITEMS, ({ clubID, socketKey }) => ({
  clubID,
  socketKey,
}));

export const readMemos = createAction(
  READ_MEMO,
  ({ clubID, targetID, socketKey }) => ({
    clubID,
    targetID,
    socketKey,
  }),
);

export const loadClubMembership = createAction(
  LOAD_CLUBMEMBERSHIP,
  ({ clubID, socketKey }) => ({ clubID, socketKey }),
);

export const loadUserMembership = createAction(
  LOAD_USER_MEMBERSHIP,
  ({ clubID, targetID, socketKey }) => ({
    clubID,
    targetID,
    socketKey,
  }),
);

export const loadUserPayment = createAction(
  LOAD_USER_PAYMENT,
  ({ playerID, socketKey }) => ({
    playerID,
    socketKey,
  }),
);

export const removeMembership = createAction(
  REMOVE_MEMBERSHIP,
  ({ clubMembershipID, clubID, socketKey, targetID }) => ({
    clubMembershipID,
    clubID,
    socketKey,
    targetID,
  }),
);
const listItemsSaga = createRequestSaga(LIST_ITEMS, itemAPI.list);
const readMemberSaga = createRequestSaga(READ_MEMBER, memberAPI.read);
const readMember_MembershipSaga = createRequestSaga(READ_MEMBER_MEMBERSHIP, memberAPI.read_membership);
const readMember_SubdataSaga = createRequestSaga(READ_MEMBER_SUBDATA, memberAPI.read_subdata);
const removeMembershipSaga = createRequestSaga(
  REMOVE_MEMBERSHIP,
  membershipAPI.removeMembership,
);

const loadClubMembershipSaga = createRequestSaga(
  LOAD_CLUBMEMBERSHIP,
  membershipAPI.findAllMembership,
);

const loadUserMembershipSaga = createRequestSaga(
  LOAD_USER_MEMBERSHIP,
  membershipAPI.findByUserMembership,
);

const loadUserPaymentSaga = createRequestSaga(
  LOAD_USER_PAYMENT,
  membershipAPI.loadUserPayment,
);

const readMemoListSaga = createRequestSaga(READ_MEMO, memoAPI.findByTargetID);

export function* membershipSaga() {
  yield takeLatest(REMOVE_MEMBERSHIP, removeMembershipSaga);
  yield takeLatest(READ_MEMBER, readMemberSaga);
  yield takeLatest(READ_MEMBER_MEMBERSHIP, readMember_MembershipSaga);
  yield takeLatest(READ_MEMBER_SUBDATA, readMember_SubdataSaga);
  yield takeLatest(LOAD_CLUBMEMBERSHIP, loadClubMembershipSaga);
  yield takeLatest(LOAD_USER_MEMBERSHIP, loadUserMembershipSaga);
  yield takeLatest(LOAD_USER_PAYMENT, loadUserPaymentSaga);
  yield takeLatest(READ_MEMO, readMemoListSaga);
  yield takeLatest(LIST_ITEMS, listItemsSaga);
}

const initialState = {
  result: null,
  clubMemberships: null,
  userMemberships: null,
  error: null,
  memo: null,
  membership: null,
  member: null,
  item: null,
  locker: null,
  coach: null,
  removeRes: null,
  paymentList: null,
  subs:null,
  memberLoadRes:null,
};

const membership = handleActions(
  {
    [REMOVE_MEMBERSHIP_SUCCESS]: (state, { payload: result }) => ({
      ...state,
      removeRes: result,
    }),
    [REMOVE_MEMBERSHIP_FAILURE]: (state, { payload: error }) => ({
      ...state,
      error,
    }),
    [LOAD_CLUBMEMBERSHIP_SUCCESS]: (state, { payload: res }) => ({
      ...state,
      clubMemberships: res,
    }),
    [LOAD_CLUBMEMBERSHIP_FAILURE]: (state, { payload: error }) => ({
      ...state,
      error,
    }),
    [LOAD_USER_PAYMENT_SUCCESS]: (state, { payload: res }) => ({
      ...state,
      paymentList: res.infos.map(item => {
        let memos = []
        let option = []

        if(item.memos) memos = JSON.parse(item.memos)
        if(item.option) option = JSON.parse(item.option)

        return {...item, memos, option,}
      }),
    }),
    [LOAD_USER_PAYMENT_FAILURE]: (state, { payload: error }) => ({
      ...state,
      error,
    }),
    [LOAD_USER_MEMBERSHIP_SUCCESS]: (state, { payload: res }) => ({
      ...state,
      membership: res,
    }),
    [LOAD_USER_MEMBERSHIP_FAILURE]: (state, { payload: error }) => ({
      ...state,
      error,
    }),
    [READ_MEMO_SUCCESS]: (state, { payload: memo }) => ({
      ...state,
      memo: memo.memo
        ? memo.memo
            .split('|#|;').reverse()
            .filter((str) => str.length !== 0)
            .map((m, idx) => ({
              id: idx,
              content: m,
              name: '내용',
            }))
        : [],
    }),

    [READ_MEMO_FAILURE]: (state, { payload: error }) => ({
      ...state,
      error,
    }),
    [LIST_ITEMS_SUCCESS]: (state, { payload: item }) => ({
      ...state,
      item,
    }),
    [LIST_ITEMS_FAILURE]: (state, { payload: error }) => ({
      ...state,
      error,
    }),
    [READ_MEMBER_SUCCESS]: (state, { payload: res }) => ({
      ...state,
      member: res.user.info,
      memberLoadRes: 1,
      memo: null,
      membership: null,
      item: null,
      locker: null,
      coach: null,
      subs:null,
    }),
    [READ_MEMBER_FAILURE]: (state, { payload: error }) => ({
      ...state,
      error,
    }),
    [READ_MEMBER_MEMBERSHIP_SUCCESS]: (state, { payload: res }) => ({
      ...state,
      item: res.item,
      membership: res.membership
    }),
    [READ_MEMBER_MEMBERSHIP_FAILURE]: (state, { payload: error }) => ({
      ...state,
      error,
    }),
    [READ_MEMBER_SUBDATA_SUCCESS]: (state, { payload: res }) => ({
      ...state,
      memo: res.memo.memo
        ? res.memo.memo
            .split('|#|;')
            .filter((str) => str.length !== 0)
            .map((m, idx) => ({
              id: idx,
              content: m,
              name: '내용',
            }))
        : [],
      locker: res.locker,
      coach: {infos: sortReverse(res.coach.infos, "name")},
      subs: res.subs,
    }),
    [READ_MEMBER_SUBDATA_FAILURE]: (state, { payload: error }) => ({
      ...state,
      error,
    }),
    [INITIALIZE_RES]: (state) => ({
      ...state,
      result: null,
      removeRes: null,
      memberLoadRes: null,
    }),
  },
  initialState,
);

export default membership;
