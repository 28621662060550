import axios from 'axios';

const config = require('../../config.json')

const client = axios.create(
);

//export const url = (config.service == 'testserver')?'https://ohtree.net:3030':(config.service == 'live')?'https://manager.healthnavi.co.kr:3030':'http://localhost:3030';

//--> test Server
export const url = 'https://ohtree.net:3030';

//--> live Server
//export const url = 'https://manager.healthnavi.co.kr:3030';

//--> local Test Server
//export const url = 'http://localhost:3030';

export default client;
