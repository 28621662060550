import React, { useEffect, useState, useRef } from 'react';
import ImageTemplateRegist from '../../components/app/ImageTemplateRegist';
import { useDispatch, useSelector } from 'react-redux';
import imageCompression from 'browser-image-compression'
import moment from 'moment';
import { 
    init,
    saveTemplate,
    setSelectedTemplate,
} from '../../modules/app/template'
import dotenv from 'dotenv'
import html2canvas from 'html2canvas'
import { set } from 'date-fns';
import { uploadImageToServer } from '../../lib/commonFunc/commonFunc';
dotenv.config()

const ImageTemplateRegistContainer = ({ history, location }) => {
    const {
      club,
      socketKey,
      createRes,
      selectedTemplate,
    } = useSelector(({ template, club, socket, loading }) => ({
      club: club.defaultClub,
      socketKey: socket.socketKey,
      createRes: template.createRes,
      selectedTemplate: template.selectedTemplate,
    }));
    
    const dispatch = useDispatch()
    const [title, setTitle] = useState("");
    const [text1, setText1] = useState("2021. 03. 01 ~ 2021. 04. 01")
    const [text2, setText2] = useState("모든 회원권 50% 할인")
    const [text3, setText3] = useState("1:1PT | 헬스 | GT | 크로스핏 | 스피닝 | 요가 | 필라테스")
    const [text4, setText4] = useState("텍스트4")

    const templateInfo = [
        {name:"template1_sm", text4: false, size:"small", image: "https://healthnavi.s3.ap-northeast-2.amazonaws.com/web/preview/template1_sm.jpg",
        text1:`O P E N  E V E N T`, text2:`모든 회원권 및 락커 30% 할인`, text3: `2021. 03. 01 ~ 2021. 03. 31`},
        {name:"template1_lg", text4: false, size:"large", image: "https://healthnavi.s3.ap-northeast-2.amazonaws.com/web/preview/template1_lg.jpg",
        text1:`헬스클럽
OPEN EVENT`, 
        text2:`- 1:1 개인 PT EVENT -

"30회 등록 시 헬스 이용권 3개월 서비스"
        
"40회 등록 시 헬스 이용권 5개월 서비스"
        
"50회 등록 시 헬스 이용권 5개월 서비스"`, 
        text3: `
- 기간 -

2021. 01. 01 ~ 2021. 03. 01`},
        {name:"template2_sm", text4: false, size:"small", image: "https://healthnavi.s3.ap-northeast-2.amazonaws.com/web/preview/template2_sm.jpg",
        text1:`모든 회원권 및 락커 30% 할인`, 
        text2:`회원권 3개월
9만원`, 
        text3: `2021. 03. 01 ~ 2021. 03. 31`},
        {name:"template2_lg", text4: false, size:"large", image: "https://healthnavi.s3.ap-northeast-2.amazonaws.com/web/preview/template2_lg.jpg",
        text1:`2021. 03. 01 ~ 2021. 03. 31`, 
        text2:`여름 맞이 PT 이벤트`, 
        text3: `여름 맞이 파격 이벤트!
- 1년 헬스 + GX = 30만원 -

핫한 GX 프로그램!
- 줌바, 다이어트댄스, 요가 -

PT 가입 회원님께 드리는 혜택!
- 스포츠 가방, 텀블러, 스포츠타월 제공 -`},
        {name:"template3_sm", text4: false, size:"small", image: "https://healthnavi.s3.ap-northeast-2.amazonaws.com/web/preview/template3_sm.jpg",
        text1:`EVENT`, 
        text2:`신규 오픈 기념
1:1 PT 할인`, 
        text3: `2021. 03. 01 ~ 2021. 03. 31`},
        {name:"template3_lg", text4: false, size:"large", image: "https://healthnavi.s3.ap-northeast-2.amazonaws.com/web/preview/template3_lg.jpg",
        text1:`2021. 03. 01 ~ 2021. 03. 31`, 
        text2:`여름 맞이
파격 이벤트!`, 
        text3: `1. 모든 헬스장 이용권 30% 할인!

2. 헬스네비 운동 포인트 20% 추가 획득!

3. 1:1PT 이용권 15% 할인`},
        {name:"template4_sm", text4: false, size:"small", image: "https://healthnavi.s3.ap-northeast-2.amazonaws.com/web/preview/template4_sm.jpg",
        text1:`2021. 03. 01 ~ 2021. 03. 31`, 
        text2:`회원권 및 락커 할인`, 
        text3: `바로가기`},
        {name:"template4_lg", text4: true, size:"large", image: "https://healthnavi.s3.ap-northeast-2.amazonaws.com/web/preview/template4_lg.jpg",
        text1:`2021. 03. 01 ~ 2021. 03. 31`, 
        text2:`모든 헬스장 이용권
30% 할인!`, 
        text3: `1. 모든 헬스장 이용권 30% 할인!

2. 헬스네비 운동 포인트 20% 추가 획득!

3. 1:1PT 이용권 15% 할인`,
        text4: `1:1PT | 헬스 | GT | 크로스핏 | 스피닝 | 요가 | 필라테스`},
        {name:"template5_sm", text4: false, size:"small", image: "https://healthnavi.s3.ap-northeast-2.amazonaws.com/web/preview/template5_sm.jpg",
        text1:`2021. 03. 01 ~ 2021. 03. 31`, 
        text2:`개인 PT 30% 할인`, 
        text3: `1:1 전문 트레이너 상담`},
        {name:"template5_lg", text4: true, size:"large", image: "https://healthnavi.s3.ap-northeast-2.amazonaws.com/web/preview/template5_lg.jpg",
        text1:`2021. 03. 01 ~ 2021. 03. 31`, 
        text2:`여름 맞이 회원권
할인 EVENT`, 
        text3: `1. 모든 헬스장 이용권 30% 할인!

2. 헬스네비 운동 포인트 20% 추가 획득!

3. 1:1PT 이용권 15% 할인`,
        text4: `- 1:1PT | 헬스 | GT | 크로스핏 | 스피닝 | 요가 | 필라테스 -`},
        {name:"template6_sm", text4: false, size:"small", image: "https://healthnavi.s3.ap-northeast-2.amazonaws.com/web/preview/template6_sm.jpg",
        text1:`2021. 03. 01 ~ 2021. 03. 31`, 
        text2:`모든 회원권 50% 할인`, 
        text3: `1:1PT | 헬스 | GT | 크로스핏 | 스피닝 | 요가 | 필라테스`},
        {name:"template6_lg", text4: true, size:"large", image: "https://healthnavi.s3.ap-northeast-2.amazonaws.com/web/preview/template6_lg.jpg",
        text1:`2021. 03. 01 ~ 2021. 03. 31`, 
        text2:`모든 회원권 50% 할인`, 
        text3: `1. 모든 헬스장 이용권 30% 할인!

2. 헬스네비 운동 포인트 20% 추가 획득!

3. 1:1PT 이용권 15% 할인`,
        text4: `1:1PT | 헬스 | GT | 크로스핏 | 스피닝 | 요가 | 필라테스`},
        {name:"template7_sm", text4: false, size:"small", image: "https://healthnavi.s3.ap-northeast-2.amazonaws.com/web/preview/template7_sm.jpg",
        text1:`OPEN EVENT`, 
        text2:`회원권 6개월
50% 할인`, 
        text3: `헬스클럽 이용권 / 요가 / 스피닝`},
        {name:"template7_lg", text4: false, size:"large", image: "https://healthnavi.s3.ap-northeast-2.amazonaws.com/web/preview/template7_lg.jpg",
        text1:`6개월 회원권
50% 할인 이벤트`, 
        text2:`헬스장 오픈 1주년을 맞이하여
6개월 회원권 50% 할인 이벤트를 진행합니다.

* 이벤트 상품 구매는 앱에서 가능합니다.`, 
        text3: `이벤트 기간 : 2021. 4. 1 ~ 2021. 4. 31`},
        {name:"template8_sm", text4: false, size:"small", image: "https://healthnavi.s3.ap-northeast-2.amazonaws.com/web/preview/template8_sm.jpg",
        text1:`헬스네비 4월 EVENT`, 
        text2:`모든 회원권 & PT 할인`, 
        text3: `2021. 4. 1 ~ 2021. 4. 30`},
        {name:"template8_lg", text4: true, size:"large", image: "https://healthnavi.s3.ap-northeast-2.amazonaws.com/web/preview/template8_lg.jpg",
        text1:`헬스네비 4월 EVENT`, 
        text2:`회원권 & PT 할인 이벤트`, 
        text3: `1. 모든 회원권 30% 할인

2. 1:1 PT 10회 이용권 10% 할인

3. 운동복 무료 이용

4. 락커 이용권 30% 할인`,
        text4: `이벤트 기간 : 2021. 4. 1 ~ 2021. 4. 31`,},
        {name:"template9_sm", text4: false, size:"small", image: "https://healthnavi.s3.ap-northeast-2.amazonaws.com/web/preview/template9_sm.jpg",
        text1:`모든 회원권`, 
        text2:`50% 할인`, 
        text3: `2021. 4. 1 ~ 2021. 4. 30`},
        {name:"template9_lg", text4: true, size:"large", image: "https://healthnavi.s3.ap-northeast-2.amazonaws.com/web/preview/template9_lg.jpg",
        text1:`모든 회원권`, 
        text2:`50% 할인 이벤트!`, 
        text3: `이벤트 기간 : 2021. 4. 1 ~ 2021. 4. 31`,
        text4: `헬스 클럽 신규 오픈을 기념하여 모든 회원권을 50%
할인 판매중입니다. 해당 이벤트 상품은 모바일 앱
상풍 페이지에서 구매 가능합니다.`,},
        {name:"template10_sm", text4: false, size:"small", image: "https://healthnavi.s3.ap-northeast-2.amazonaws.com/web/preview/template10_sm.jpg",
        text1:`4월 CLASS`, 
        text2:`요가 & 필라테스
다이어트반 모집`, 
        text3: `인원 제한 있으니 빨리 신청하세요!`},
        {name:"template10_lg", text4: false, size:"large", image: "https://healthnavi.s3.ap-northeast-2.amazonaws.com/web/preview/template10_lg.jpg",
        text1:`4월 CLASS`, 
        text2:`요가 & 필라테스
다이어트반 모집`, 
        text3: `1. 혼자 다이어트하기 힘드신 분
2.다이어트 방법을 모르시는 분
3.다이어트를 자주 실패하시는분`,
        },
        {name:"template11_sm", text4: false, size:"small", image: "https://healthnavi.s3.ap-northeast-2.amazonaws.com/web/preview/template11_sm.jpg",
        text1:`헬스네비앱 사용 시`, 
        text2:`500 Point
즉시 지급`, 
        text3: `PT예약 & 공지 확인도 간편하게!`},
        {name:"template11_lg", text4: false, size:"large", image: "https://healthnavi.s3.ap-northeast-2.amazonaws.com/web/preview/template11_lg.jpg",
        text1:`헬스네비 앱 이용 혜택`, 
        text2:`1. 가입, 출석 시 포인트 획득
2. 유산소 및 웨이트 운동 시 포인트 획득
3. 각종 이벤트 참여 및 소식 확인 가능
4. 간편한 PT 및 수업 예약
5. 모바일 간편 상품 구매`, 
        text3: `※ 문의 사항 : 02) 1234 - 5678`,
        },
        {name:"template12_sm", text4: false, size:"small", image: "https://healthnavi.s3.ap-northeast-2.amazonaws.com/web/preview/template12_sm.jpg",
        text1:`헬스클럽 입장 시`, 
        text2:`마스크 착용 필수!`, 
        text3: `마스크 미착용 시 입장이 불가능합니다.`},
        {name:"template12_lg", text4: false, size:"large", image: "https://healthnavi.s3.ap-northeast-2.amazonaws.com/web/preview/template12_lg.jpg",
        text1:`입장 시 마스크 착용 필수!`, 
        text2:`코로나 단게 격상 및 정부 지침에 따라서
마스크 미착용 시 입장이 불가능하며 운동 중에도
반드시 마스크를 착용해야 합니다.`, 
        text3: `마스크 미착용 상태로 운동 시 과태료가
부가될 수 있으며 회원간 대화는 삼가해 주시기 바랍니다.`,
        },
        {name:"template13_sm", text4: false, size:"small", image: "https://healthnavi.s3.ap-northeast-2.amazonaws.com/web/preview/template13_sm.jpg",
        text1:`이유없이 좋아지는`, 
        text2:`몸매는 없다.`, 
        text3: `#필라테스 #요가 #다이어트 #1:1PT`},
        {name:"template13_lg", text4: false, size:"large", image: "https://healthnavi.s3.ap-northeast-2.amazonaws.com/web/preview/template13_lg.jpg",
        text1:`이유없이 좋아지는`, 
        text2:`몸매는
없다.`, 
        text3: `#필라테스 #요가 #스피닝
#다이어트 #1:1PT`,
        },
        {name:"template14_sm", text4: false, size:"small", image: "https://healthnavi.s3.ap-northeast-2.amazonaws.com/web/preview/template14_sm.jpg",
        text1:`이유없이 커지는`, 
        text2:`근육은 없다.`, 
        text3: `#웨이트 #유산소 #프로틴 #1:1PT`},
        {name:"template14_lg", text4: false, size:"large", image: "https://healthnavi.s3.ap-northeast-2.amazonaws.com/web/preview/template14_lg.jpg",
        text1:`이유없이 커지는`, 
        text2:`근육은
없다.`, 
        text3: `#웨이트 #유산소 
#프로틴 #1:1PT`,
        },
    ]

    const getTemplate = async () => {
        
        window.scroll(0, 0)
        
        let url = "";


        await html2canvas(document.getElementById("template")).then(async (canvas) => {
            //url = await canvas.toDataURL("image/jpg");
              
            const toblob = async (canvas) =>{
                return new Promise((resolve, reject) => {
                    canvas.toBlob(file => {
                    resolve(file)
                    }, 'image/jpeg')
                })
            } 
            url = await toblob(canvas);
            
        });
    
        let resp = await saveImageToAWS(url);

        return resp;
    }

    const _backConfirm = async () => {
        let event = window.confirm(
          '페이지에서 나가시겠습니까? 변경된 정보는 저장되지 않습니다.',
        );
        if (event) {
          history.push(`/mobile/imageTemplate`);
        }
    };
    
    const saveImageToAWS = async (url) => {

        let _templateURL = club.clubID + "_template" + "_" + Math.floor(Date.now() / 1000).toString() + '.jpg';

        let result = await uploadImageToServer(url, _templateURL, 'clubdata/template', url.type);

        console.log("result : ", result)
        return _templateURL;

        // var AWS = require('aws-sdk');
        // var s3 = new AWS.S3({accessKeyId: process.env, secretAccessKey: process.env.REACT_APP_AWS_ACCESS_KEY, region:'ap-northeast-2'});

        // let _templateURL = club.clubID + "_template" + "_" + Math.floor(Date.now() / 1000).toString() + '.jpg';

        // //--> upload

        // //let contentType = 'image/jpeg';
        // let contentDeposition = 'inline;filename="' + _templateURL + '"';
        // //const base64 = await RNFS.readFile(imagePath, 'base64');
        // const base64Data = new Buffer.from(url.replace(/^data:image\/\w+;base64,/, ""), 'base64');
        // const type = url.split(';')[0].split('/')[1];
        // //const arrayBuffer = decode(imagePath);

        // const paramsupload = {
        //     Bucket: "healthnavi",
        //     Key: 'clubdata/template/'+_templateURL,
        //     ACL:'public-read',
        //     Body: base64Data,
        //     ContentDisposition: contentDeposition,
        //     ContentType: type,
        // };
        // let uploadresult = await new Promise((resolve, reject) => {
        //     s3.upload(paramsupload, (err, data) => {
        //         if (err) {
        //             reject(err);
        //         }
        //         resolve(data);
        //     });
        // });
        
        // return _templateURL;
    }

    const onSaveTemplate = async () => {
      if(!title || title == "")  {
        alert("이미지 제목을 입력해주세요.")
        return false;
      }

      let url = await getTemplate()

      let clubTemplateData = {
        title,
        templateURL: url,
        size: selectedTemplate.size,
        registTime: Math.ceil(new Date().getTime() / 1000),
        isUsing: 0
      }

      dispatch(saveTemplate({
        clubID: club.clubID,
        clubTemplateData,
        socketKey,
      }))
    }

    const onSetSelectedTemplate = (template) => {
      dispatch(setSelectedTemplate({template}))
    }
    
    useEffect(() => {
      window.history.pushState(null, '', window.location.href);
      window.onpopstate = _backConfirm;
    }, [location]);
  
    useEffect(() => {
      return () => {
        window.onpopstate = () => {};
      };
    }, []);

    useEffect(() => {
        if(createRes && createRes == 1) {
            alert("이미지를 저장했습니다.")

            //dispatch(init())
            history.push(
                `/mobile/imageTemplate`,
            );
        }
    }, [createRes]);

    useEffect(() => {
      if(!selectedTemplate) {
        alert("템플릿을 다시 선택해주세요.")
        history.push(`/mobile/imageTemplate`)
      }
    }, []);

    useEffect(() => {
      if(selectedTemplate) {
        setText1(selectedTemplate.text1)
        setText2(selectedTemplate.text2)
        setText3(selectedTemplate.text3)
        if(selectedTemplate.text4) setText4(selectedTemplate.text4)
      }
    }, [selectedTemplate]);

    console.log(selectedTemplate, 'selectedTemplate')

    return (
        <ImageTemplateRegist
          _backConfirm={_backConfirm}
          title={title}
          setTitle={setTitle}
          onSaveTemplate={onSaveTemplate}
          selectedTemplate={selectedTemplate}
          onSetSelectedTemplate={onSetSelectedTemplate}
          text1={text1}
          setText1={setText1}
          text2={text2}
          setText2={setText2}
          text3={text3}
          setText3={setText3}
          text4={text4}
          setText4={setText4}
          templateInfo={templateInfo}
        />
    );
};

export default ImageTemplateRegistContainer;
