import React, { useEffect, useState, useCallback } from 'react';
import MemberData from '../../../components/member/MemberData';
import { withRouter } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  initialize,
} from '../../../modules/member/sale';
import {
  loadUserMembership,
} from '../../../modules/member/membership';
import {
  initializeGoodsRegistRes,
  loadUserGoods,
} from '../../../modules/member/goods';
import { readPointUser } from '../../../modules/member/point';

const MemberDataContainer = ({ match, history }) => {
  const dispatch = useDispatch();

  const {
    member,
    club,
    locker,
    memberships,
    loading,
    socketKey,
    saleResult,
    saleGoodsResult,
    goods,
    targetID,
    subs
  } = useSelector(
    ({ member, club, sale, loading, socket, membership, goods }) => ({
      member: membership.member,
      memberInfo: membership,
      targetID: member.playerID,
      club: club.defaultClub,
      memberships: membership.membership,
      goods: goods.goods,
      locker: membership.locker,
      selectedItem: sale,
      saleResult: sale.result,
      loading: loading['membership/READ_MEMBER'],
      socketKey: socket.socketKey,
      saleGoodsResult: goods.registRes,
      subs: membership.subs
    }),
  );
  const [modals, setModals] = useState([
    {
      id: 'modal',
      visible: false,
    },
    {
      id: 'hold',
      visible: false,
    },
    {
      id: 'modify',
      visible: false,
    },
    {
      id: 'remove',
      visible: false,
    },
    {
      id: 'sale',
      visible: false,
    },
    {
      id: 'refund',
      visible: false,
    },
    {
      id: 'cancelHolding',
      visible: false,
    },
  ]);
  
  const onOpenModal = useCallback((id) => {
  
    if(id === 'sale')
    {
        dispatch(
            readPointUser({
            clubID: club.clubID,
            targetID: targetID,
            socketKey,
          }),
        );
    }
    setModals((modals) =>
      modals.map((modal) =>
        modal.id === id ? { ...modal, visible: true } : modal,
      ),
    );
  }, []);
  
  const onCloseModal = useCallback(
    (id) => {
      setModals((modals) =>
        modals.map((modal) =>
          modal.id === id ? { ...modal, visible: false } : modal,
        ),
      );
    },
    [dispatch],
  );
  
  useEffect(() => {
    if (saleResult) {
      switch (saleResult.result) {
        case 1:
          alert('상품 판매가 정상적으로 완료되었습니다.');
          dispatch(
            loadUserMembership({
              clubID: club.clubID,
              targetID: member.playerID,
              socketKey,
            }),
          );
          onCloseModal('sale');
          dispatch(initialize());
          break;
        default:
          alert('상품 판매가 실패하였습니다. 잠시 후 다시시도해 주세요.');
      }
    }
  }, [saleResult]);
  
  useEffect(() => {
    if (saleGoodsResult) {
      switch (saleGoodsResult.result) {
        case 1:
          alert('상품 판매가 정상적으로 완료되었습니다.');
          dispatch(
            loadUserGoods({
              clubID: club.clubID,
              targetID: member.playerID,
              socketKey,
            }),
          );
          onCloseModal('sale');
          dispatch(initializeGoodsRegistRes());
          break;
        default:
          alert('상품 판매가 실패하였습니다. 잠시 후 다시시도해 주세요.');
      }
    }
  }, [saleGoodsResult]);

  useEffect(() => {
    if(club && member) {
      dispatch(
        loadUserGoods({
          clubID: club.clubID,
          targetID: member.playerID,
          socketKey,
        }),
      );
    }
  }, [club, member])

  console.log('subs : ', subs)

  return(
    <>
      <MemberData 
        onOpenModal={onOpenModal}
        onCloseModal={onCloseModal}
        modals={modals}
        loading={loading}
        memberships={memberships}
        goods={goods}
        locker={locker}
        subs={subs}
      />
    </>
  )
};

export default withRouter(MemberDataContainer);
