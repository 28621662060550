import qs from 'qs';
import client, { url } from './client';

export const allMember = ({ clubID, socketKey }) => {
  const queryString = qs.stringify({
    clubID,
    socketKey,
  });
  return client.get(`${url}/api/user?${queryString}`);
};

export const checkAccount = ({ account, socketKey }) => {
  const queryString = qs.stringify({
    socketKey,
  });
  return client.post(`${url}/api/user/check${queryString}`, { account });
};

export const read = ({ clubID, targetID, socketKey }) => {
  const queryString = qs.stringify({
    clubID,
    socketKey,
  });

  return client.get(`${url}/api/user/${targetID}?${queryString}`);
};

export const read_membership = ({ clubID, targetID, socketKey }) => {
  const queryString = qs.stringify({
    clubID,
    socketKey,
  });

  return client.get(`${url}/api/user/membership/${targetID}?${queryString}`);
};

export const read_subdata = ({ clubID, targetID, socketKey }) => {
  const queryString = qs.stringify({
    clubID,
    socketKey,
  });

  return client.get(`${url}/api/user/subdata/${targetID}?${queryString}`);
};


export const readMember = ({ clubID, targetID, socketKey }) => {
    const queryString = qs.stringify({
      clubID,
      socketKey,
    });
  
    return client.get(`${url}/api/user/memberread/${targetID}?${queryString}`);
  };

export const removeMember = ({ clubID, targetID, socketKey }) => {
  const queryString = qs.stringify({
    clubID,
    socketKey,
  });

  return client.delete(`${url}/api/user/${targetID}?${queryString}`);
}

export const changeProfile = ({ targetID, socketKey, clubProfileURL}) => {
  const queryString = qs.stringify({
    socketKey,
  });

  return client.post(`${url}/api/user/${targetID}?${queryString}`, {clubProfileURL});
}