import React, { useRef, useCallback, useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md';
import { FiCalendar } from 'react-icons/fi';
import Loading from '../common/Loading';
import Calendar from './Canlendar';
import palette from '../../lib/styles/palette';
import SalesTable from './SalesTable';
import MobileHistoryTable from './MobileHistoryTable'
import RefundTable from './RefundTable';
import MonthPicker from './MonthPicker';
import Button from '../common/Button';
import MonthlyTable from './MonthlyTable';
import { download } from '../../lib/excelDownload';
import { inputNumberAutoComma, timeValueToDate } from '../../lib/commonFunc/commonFunc';
import categoryMap from '../../lib/def/category';
import Chart from './Chart';
import moment from 'moment';

const Wrapper = styled.div`
  width: 100%;
  max-width: 1400px;
  flex: 1;
  justify-content: center;
  align-items: center;
  height: auto;
  padding-left: 2rem;

  ${(props) =>
        props.isLoading &&
        css`
      display: flex;
    `}
`;

const DateControllBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18pt;
  margin-bottom: 15px;
  .previous {
    margin-right: 8px;
  }
  .next {
    margin-left: 8px;
  }
`;
const DateInnerBlock = styled.div`
  width: 70%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const MonthBlock = styled.div`
  display: flex;
  align-items: center;
`;

const InnerMonthBlock = styled.div`
  & + & {
    margin-left: 20px;
  }

  display: flex;
  align-items: center;

  svg {
    cursor: pointer;
  }
`;

const DateControllBtn = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  svg {
    font-size: 28pt;
    margin-top: 1px;
  }
`;

const BtnBlock = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 15px;
`;

const BtnBlockA = styled.div`
  display: flex;
  margin-bottom: 15px;
`;

const SummaryBlock = styled.div`
  display: flex;
  margin: 2rem 0 1rem 0;
  min-height: 21rem;
`;

const GrayBox = styled.div`
  border: 1px solid #ddd;
  padding: 15px 20px;
  overflow-x:auto;
  flex-direction: column;
  box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.05);
  border-radius: .3rem;

  &::-webkit-scrollbar {
      height: 15px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${palette.webGray[4]};
    border-radius: 10px;
    background-clip: padding-box;
    border: 2px solid transparent;
  }
  &::-webkit-scrollbar-track {
    background-color: ${palette.webGray[2]};
    border-radius: 10px;
    box-shadow: inset 0px 0px 5px white;
  }
  
  & + & {
    margin-left: 1rem;
  }
  ${(props) =>
        props.ratio &&
        css`
      flex: ${props.ratio};
    `}
  ${(props) =>
        props.bigPadding &&
        css`
      padding: 60px 30px;
    `}
  ${(props) =>
        props.nonePadding &&
        css`
      padding: 0px 0px;
    `}
  ${(props) =>
        props.isFlex &&
        css`
      display: flex;
    `}
`;

const HalfWidthBlock = styled.div`
  flex:1;
`;

const MonthlySummary = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin-left: 10px;
`;
const MonthlyCategory = styled.div`
  display: flex;
  justify-content: left;

  align-items: center;
  font-size: 16px;
  font-family: 'Noto Sans KR', sans-serif;
  font-weight: 500;
`;

const ChartLegend = styled.div`
  width: 15px;
  height: 15px;  

  ${(props) =>
        css`
      background: ${chartColor[props.colorIndex]};
      `}
`;
const MonthlyText = styled.div`
  white-space: nowrap;
  width: 160px;
  margin-left: 20px;
  @media screen and (max-width: 1600px) {
    font-size: 0.9rem;
  }
`;
const MonthlySales = styled.div`
  white-space: nowrap;
  @media screen and (max-width: 1600px) {
    font-size: 0.9rem;
  }
`;
const CategoryWrapper = styled.div`
  display: flex;
  height: auto;
`;

const PaymentBlock = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 10px 0;
  font-family: 'Noto Sans KR', sans-serif;
  background: ${palette.webGray[17]};
  margin: 5px 0 120px 0;
  border-radius: .2rem;
`;
const PaymentSummaryLine = styled.div`
  display: flex;
`;
const PaymentText = styled.p`
  font-size: 14px;
  font-weight: 600;
  color: ${palette.webGray[9]};
  & + & {
    margin-left: 15px;
  }
  ${(props) =>
        props.emphasis &&
        css`
      font-size: 16pt;
      color: ${palette.webGray[13]};
    `}
`;
const TabBlock = styled.div`
  display: flex;
  margin-bottom: 2rem;
`;

const TotalRefund = styled.div`
  display: flex;
  align-items: center;
  padding-left: 20px;
  border-left: 1px solid ${palette.webGray[5]};
  margin-left: 20px;

  & div {
    font-weight: normal;
    font-size: 1rem;

    &.sub {
      margin-right: 15px;
    }
  }
`;

const TabText = styled.div`
  font-size: 16pt;
  cursor: pointer;
  & + & {
    margin-left: 50px;
  }
  ${(props) =>
        props.active &&
        css`
      font-weight: 700;
      color: ${palette.webCyan[5]};
    `}
`;

const Table = styled.table`
  font-family: 'Noto Sans KR', sans-serif;
  td {
    padding: 10px;
    border-top: 1px solid ${palette.webGray[15]};
  }

  thead {
    tr {
      border-top: 2px solid ${palette.webGray[7]};
      border-bottom: 2px solid ${palette.webGray[7]};
    }
  }
`;

const CalendarBlock = styled.div``;
const chartColor = [
    '#FF6384',
    '#36A2EB',
    '#FFCE56',
    '#7FDBFF',
    '#878BB6',
    '#3cba9f',
    '#c45850',
    '#F5DEB3',
];

const CalculateWrap = (ref, viewMoreBtn) => {
    function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
            viewMoreBtn(false);
        }
    }

    useEffect(() => {
        // Bind the event listener
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener('mousedown', handleClickOutside);
        };
    });
};

const Sales = ({
    onLoadPrevMonth,
    onLoadNextMonth,
    isLoading,
    month,
    active,
    onSetTab,
    onClickDate,
    items,
    memberships,
    salesByDate,
    onToggleMonthPicker,
    monthPicker,
    onChangeMonth,
    tableData,
    refundData,
    goods,
    salesInfo,
    onClickCancelPayment,
    onClickCancelPaymentAdmin,
    selectedDay,
    clubPaymentList,
    subsData
}) => {
    const [monthlyTableData, setMonthlyTableData] = useState(null)
    const [dailyCalendarData, setDailyCalendarData] = useState(null)
    const [dailyTableData, setDailyTableData] = useState(null)
    const [dailyRefundData, setDailyRefundData] = useState(null)
    const [dailyMobileHistoryData, setDailyMobileHistoryData] = useState(null)
    const [calculateModal, setCalculateModal] = useState(false)
    const [calculateData, setCalculateData] = useState([])
    const wrapperRef = useRef(null);
    const [subsItemList, setSubsItemList] = useState([])
    const [selectSubsItemIndex, setSelectSubsItemIndex] = useState(0)
    const [subsTableData, setSubsTableData] = useState([])
    const [subsSelectTableData, setSubsSelectTableData] = useState('')

    CalculateWrap(wrapperRef, setCalculateModal);

    const [monthOption, setMonthOption] = useState({
        showMobile: true,
        showCounter: true,
        disableNoneItem: false,
    })
    const [dayOption, setDayOption] = useState({
        showMobile: true,
        showCounter: true,
    })
    const [mobileOption, setMobileOption] = useState({
        showMembership: true,
        showSubscription: true,
    })


    console.log('subsData : ', subsData)
    

    useEffect(() => {
        if (memberships) {
            let tempData = { ...memberships }

            for (let k in tempData) {
                tempData[k] = tempData[k].filter(membership => {
                    if (!monthOption.showCounter && (!membership.paymentID || membership.paymentID === "")) return false;
                    if (!monthOption.showMobile && (membership.paymentID && membership.paymentID !== "")) return false;
                    return membership
                })
            }

            setMonthlyTableData(tempData)
        }
    }, [memberships, monthOption])

    useEffect(() => {
        if (clubPaymentList) {
            console.log('clubPaymentList : ', clubPaymentList)
            let tempData = []
            let thisYear = new Date(month).getFullYear()
            let thisMonth = new Date(month).getMonth()

            for (let i = 0; i < clubPaymentList.length; i++) {
                let paymentYear = new Date(clubPaymentList[i].registTime * 1000).getFullYear()
                let paymentMonth = new Date(clubPaymentList[i].registTime * 1000).getMonth()

                let parseOption = null
                if (clubPaymentList[i].option && clubPaymentList[i].option !== "") parseOption = JSON.parse(clubPaymentList[i].option).map(op => { return op.name })

                if (paymentYear === thisYear && paymentMonth === thisMonth) {
                    tempData.push({ ...clubPaymentList[i], option: parseOption })
                }
            }

            setDailyMobileHistoryData(tempData)
        }
    }, [clubPaymentList, month])

    useEffect(() => {
        if (tableData) {
            let tempData = []

            for (let i = 0; i < tableData.length; i++) {
                tempData.push({ ...tableData[i] })
            }

            for (let i = 0; i < tempData.length; i++) {
                tempData[i].data = tempData[i].data.filter(membership => {
                    if (!dayOption.showCounter && (!membership.paymentID || membership.paymentID === "")) return false;
                    if (!dayOption.showMobile && (membership.paymentID && membership.paymentID !== "")) return false;
                    return membership
                })
            }

            tempData = tempData.filter(item => {
                if (item.data && item.data.length > 0) return item
            })

            tempData = tempData.map(temp => {
                return {
                    ...temp,
                    data: temp.data.map(item => {
                        let payment = clubPaymentList.filter(paymentitem => paymentitem.paymentID === item.paymentID)
                        let parseOption = null
                        if(payment.length > 0)
                        {
                            //console.log('payment : ', payment)
                            if (payment[0].option && payment[0].option !== "") parseOption = JSON.parse(payment[0].option).map(op => { return op.name })
                            
                        }
                        //if (item.option && item.option !== "") parseOption = JSON.parse(item.option).map(op => { return op.name })
                        return {
                            ...item,
                            option: parseOption
                        }
                    })
                }
            })

            setDailyTableData(tempData)
        }
    }, [tableData, dayOption, clubPaymentList])

    useEffect(() => {
        if (refundData) {
            let tempData = []

            for (let i = 0; i < refundData.length; i++) {
                tempData.push({ ...refundData[i] })
            }

            for (let i = 0; i < tempData.length; i++) {
                tempData[i].data = tempData[i].data.filter(membership => {
                    if (!dayOption.showCounter && (!membership.paymentID || membership.paymentID === "")) return false;
                    if (!dayOption.showMobile && (membership.paymentID && membership.paymentID !== "")) return false;
                    return membership
                })
            }

            tempData = tempData.filter(item => {
                if (item.data && item.data.length > 0) return item
            })

            tempData = tempData.map(temp => {
                return {
                    ...temp,
                    data: temp.data.map(item => {
                        let parseOption = []
                        if (item.option && item.option !== "") parseOption = JSON.parse(item.option).map(op => { return op.name })
                        return {
                            ...item,
                            option: parseOption
                        }
                    })
                }
            })

            setDailyRefundData(tempData)
        }
    }, [refundData, dayOption])

    useEffect(() => {
        if (salesByDate) {
            let tempData = { ...salesByDate }

            for (let k in tempData) {
                tempData[k] = tempData[k].filter(membership => {
                    if (!dayOption.showCounter && (!membership.paymentID || membership.paymentID === "")) return false;
                    if (!dayOption.showMobile && (membership.paymentID && membership.paymentID !== "")) return false;
                    return membership
                })
            }

            setDailyCalendarData(tempData)
        }
    }, [salesByDate, dayOption])

    useEffect(() => {
        if (clubPaymentList && month && clubPaymentList.length > 0) {
            let feesRatio = 0.045
            let thisYear = new Date(month).getFullYear()
            let thisMonth = new Date(month).getMonth()
            let lastDay = new Date(thisYear, thisMonth + 1, 0).getDate()
            let thisPayment = clubPaymentList.filter(item => new Date(item.registTime * 1000).getFullYear() === thisYear && new Date(item.registTime * 1000).getMonth() === thisMonth && item.refundPrice === 0)

            let calculateInfo = []

            for (let i = 0; i < 3; i++) {
                let registTime = ""
                let calculateTime = ""
                let card = 0
                let point = 0
                let calculatePrice = 0
                let fees = 0

                if (i === 0) {
                    registTime = `${thisMonth + 1}월 1일 ~ ${thisMonth + 1}월 10일`
                    calculateTime = timeValueToDate(Math.ceil(new Date(thisYear, thisMonth, 21).getTime() / 1000))
                } else if (i === 1) {
                    registTime = `${thisMonth + 1}월 11일 ~ ${thisMonth + 1}월 20일`
                    calculateTime = timeValueToDate(Math.ceil(new Date(thisYear, thisMonth + 1, 1).getTime() / 1000))
                } else if (i === 2) {
                    registTime = `${thisMonth + 1}월 21일 ~ ${thisMonth + 1}월 ${lastDay}일`
                    calculateTime = timeValueToDate(Math.ceil(new Date(thisYear, thisMonth + 1, 11).getTime() / 1000))
                }

                calculateInfo.push({
                    registTime,
                    calculateTime,
                    card,
                    point,
                    calculatePrice,
                    fees
                })
            }

            console.log(calculateInfo, 'calculateInfo!!!')

            for (let i = 0; i < thisPayment.length; i++) {
                let num = 0
                let thisPaymentRegistTime = new Date(thisPayment[i].registTime * 1000)

                if(1724770799 >= thisPayment[i].registTime) {
                    continue;
                }
                if (thisPaymentRegistTime.getDate() >= 1 && thisPaymentRegistTime.getDate() <= 10) {
                    num = 0
                } else if (thisPaymentRegistTime.getDate() >= 11 && thisPaymentRegistTime.getDate() <= 20) {
                    num = 1
                } else if (thisPaymentRegistTime.getDate() >= 21 && thisPaymentRegistTime.getDate() <= lastDay) {
                    num = 2
                }

                let thisCard = thisPayment[i].salePrice
                let thisFees = Math.floor(thisCard * feesRatio)

                calculateInfo[num] = {
                    ...calculateInfo[num],
                    card: calculateInfo[num].card + thisCard,
                    point: calculateInfo[num].point + thisPayment[i].usedPoint,
                    //fees: calculateInfo[num].fees + thisFees,
                    //calculatePrice: calculateInfo[num].calculatePrice + (thisCard - (thisFees))
                    fees: 0,
                    calculatePrice: calculateInfo[num].calculatePrice + (thisCard)
                }
            }

            for (let i = 0; i < calculateInfo.length; i++) {
                calculateInfo[i].fees = Math.floor(calculateInfo[i].card * feesRatio);
                calculateInfo[i].calculatePrice = calculateInfo[i].card - calculateInfo[i].fees
            }

            setCalculateData(calculateInfo)
        }
    }, [clubPaymentList, month])

    if (isLoading)
        return (
            <Wrapper isLoading>
                <Loading />
            </Wrapper>
        );

    let refundTotal = 0;

    if (refundData && refundData.length > 0) {
        let temp = [];

        for (let i = 0; i < refundData.map(item => item.data).length; i++) {
            for (let j = 0; j < refundData.map(item => item.data)[i].length; j++) {
                if (monthOption.showCounter && monthOption.showMobile) {
                    temp.push(refundData.map(item => item.data)[i][j])
                } else if (monthOption.showCounter && refundData.map(item => item.data)[i][j].paymentID === "") {
                    temp.push(refundData.map(item => item.data)[i][j])
                } else if (monthOption.showMobile && refundData.map(item => item.data)[i][j].paymentID !== "") {
                    temp.push(refundData.map(item => item.data)[i][j])
                }
            }
        }

        for (let i = 0; i < temp.length; i++) {
            refundTotal += temp[i].refundPrice;
        }
    }

    const calcMonthlyStatistics = () => {

        const statistics = {
            unpayment: 0,
            card: 0,
            cash: 0,
            point: 0,
            pay: 0,
            category: [],
            sales: 0,
        };

        if (!monthlyTableData) return statistics

        for (let category in monthlyTableData) {
            const categorySummation = monthlyTableData[category].reduce(
                (result, el) => {
                    return {
                        totalPrice: (result.totalPrice += el.price),
                        totalUnpayment: (result.totalUnpayment += el.unpayment),
                        totalCardIncome: (result.totalCardIncome += el.card),
                        totalCashIncome: (result.totalCashIncome += el.cash),
                        totalPointIncome: (result.totalPointIncome += el.point),
                        totalPayIncome: (result.totalPayIncome += el.pay),
                    };
                },
                {
                    totalPrice: 0,
                    totalUnpayment: 0,
                    totalCardIncome: 0,
                    totalCashIncome: 0,
                    totalPointIncome: 0,
                    totalPayIncome: 0,
                },
            );
            statistics['category'] = statistics.category
                .concat({
                    name: category,
                    price: categorySummation.totalPrice,
                })
                .sort((a, b) => (categoryMap[a.name] > categoryMap[b.name] ? 1 : -1));
            statistics['unpayment'] = statistics['unpayment'] +=
                categorySummation.totalUnpayment;
            statistics['card'] = statistics['card'] +=
                categorySummation.totalCardIncome;
            statistics['cash'] = statistics['cash'] +=
                categorySummation.totalCashIncome;
            statistics['point'] = statistics['point'] +=
                categorySummation.totalPointIncome;
            statistics['pay'] = statistics['pay'] +=
                categorySummation.totalPayIncome;
            statistics['sales'] = statistics['cash'] + statistics['card'] + statistics['point'] + statistics['pay'];
        }

        return statistics;
    };

    let customItem = new Map()

    for (const key in items) {
        let data = items[key];

        if (memberships.hasOwnProperty(key)) {
            customItem[key] = items[key];
            continue;
        }

        let isItem = false;
        for (let i = 0; i < data.length; i++) {
            if (data[i].isDelete == 0) {
                isItem = true;
                break;
            }
        }

        if (isItem) {
            customItem[key] = items[key];
        }
    }


    const isSubs = ()=>{
        if(!dailyMobileHistoryData)
        {
            return false;
        }
        
        for (let i = 0; i < dailyMobileHistoryData.length; i++) {
            if(dailyMobileHistoryData[i].salesType == 100)
            {
                return true;
            }
        }

        return false;
    }

    const isSubsUse = ()=>{
        for (const key in items) {
            let data = items[key];
            for (let i = 0; i < data.length; i++) {
                if(data[i].type == 100)
                {
                    return true;
                }
            }
        }

        return false;
    }

    const getPlayerPhone = (playerId)=>{
        for (let i = 0; i < subsData.playerInfos.length; i++) {
            if(subsData.playerInfos[i].playerID == playerId) {
                return subsData.playerInfos[i].phone
            }
        }

        return '-'
    }


    const resetSubsItemList = ()=>{
        console.log("subsData.infos : ", subsData.infos)
        let subslist = []
        for (let i = 0; i < subsData.infos.length; i++) {
            subslist.push(subsData.infos[i].itemTitle)
        }
        for (let i = 0; i < subsData.cancelInfos.length; i++) {
            subslist.push(subsData.cancelInfos[i].itemTitle)
        }
        for (let i = 0; i < subsData.willInfos.length; i++) {
            subslist.push(subsData.willInfos[i].itemTitle)
        }
        let _set = new Set(subslist)
        setSubsItemList([..._set]);
        setSelectSubsItemIndex(0);
        setTableData('total', [..._set]);
    }

    const getTotalSubsCount = ()=>{        
        let result = 0
        for (let i = 0; i < subsData.infos.length; i++) {
            if(subsData.infos[i].itemTitle == subsItemList[selectSubsItemIndex])
            {
                result++;
            }
        }
        for (let i = 0; i < subsData.willInfos.length; i++) {
            if(subsData.willInfos[i].itemTitle == subsItemList[selectSubsItemIndex])
            {
                result++;
            }
        }

        return result;
    }
    const getCancelSubsTotalCount = ()=>{        
        let result = 0
        for (let i = 0; i < subsData.cancelInfos.length; i++) {
            if(subsData.cancelInfos[i].itemTitle == subsItemList[selectSubsItemIndex])
            {
                result++;
            }
        }

        return result;
    }
    const getCancelSubsCount = ()=>{        
        let result = 0
        let today = parseInt(Date.now()/1000)
        for (let i = 0; i < subsData.cancelInfos.length; i++) {
            if(subsData.cancelInfos[i].itemTitle == subsItemList[selectSubsItemIndex] && subsData.cancelInfos[i].payTime <= today)
            {
                result++;
            }
        }

        return result;
    }
    const getWillCancelSubsCount = ()=>{        
        let result = 0
        let today = parseInt(Date.now()/1000)
        for (let i = 0; i < subsData.cancelInfos.length; i++) {
            if(subsData.cancelInfos[i].itemTitle == subsItemList[selectSubsItemIndex] && subsData.cancelInfos[i].payTime > today)
            {
                result++;
            }
        }

        return result;
    }
    const getPaymentSubsCount = ()=>{      
        let result = 0
        for (let i = 0; i < subsData.infos.length; i++) {
            if(subsData.infos[i].itemTitle == subsItemList[selectSubsItemIndex])
            {
                result++;
            }
        }

        return result;
    }
    const getwillSubsCount = ()=>{     
        let result = 0
        for (let i = 0; i < subsData.willInfos.length; i++) {
            if(subsData.willInfos[i].itemTitle == subsItemList[selectSubsItemIndex])
            {
                result++;
            }
        }

        return result;
    }

    
    const getPaymentFirstSubsCount = ()=>{
        let result = 0
        const startTime = parseInt(moment(month).startOf('month').unix());
        const endTime = parseInt(moment(month).endOf('month').unix());
        for (let i = 0; i < subsData.infos.length; i++) {
            let _registDate = new Date(subsData.infos[i].registTime * 1000)
            _registDate.setMonth(_registDate.getMonth() + 1)
            _registDate.setDate(_registDate.getDate() - 1)
            const isFirst = (parseInt(_registDate.getTime()/1000) <= subsData.infos[i].endTime) && (parseInt(_registDate.getTime()/1000) >= subsData.infos[i].startTime)
            //console.log('aaaa : ', parseInt(_registDate.getTime()/1000), subsData.infos[i].endTime)
            console.log(`최초 결제일 : ${timeValueToDate(subsData.infos[i].registTime)} , 시작일 : ${timeValueToDate(subsData.infos[i].startTime)}`)
            if(subsData.infos[i].itemTitle == subsItemList[selectSubsItemIndex] && isFirst)
            {
                result++;
            }
        }

        return result;
    }

    const getPaymentMoreSubsCount = ()=>{  
        let result = 0
        const startTime = parseInt(moment(month).startOf('month').unix());
        const endTime = parseInt(moment(month).endOf('month').unix());
        for (let i = 0; i < subsData.infos.length; i++) {
            let _registDate = new Date(subsData.infos[i].registTime * 1000)
            _registDate.setMonth(_registDate.getMonth() + 1)
            _registDate.setDate(_registDate.getDate() - 1)
            //console.log('aaaa : ', parseInt(_registDate.getTime()/1000), subsData.infos[i].endTime)
            const isFirst = (parseInt(_registDate.getTime()/1000) <= subsData.infos[i].endTime) && (parseInt(_registDate.getTime()/1000) >= subsData.infos[i].startTime)
            if(subsData.infos[i].itemTitle == subsItemList[selectSubsItemIndex] && !(isFirst))
            {
                result++;
            }
        }

        return result;
    }


    const setTableData = (_state, _subsItemList)=>{
        let list = []
        if(_state == 'total')
        {
            for (let i = 0; i < subsData.infos.length; i++) {
                if(subsData.infos[i].itemTitle == _subsItemList[selectSubsItemIndex])
                {
                    list.push({...subsData.infos[i], phone:getPlayerPhone(subsData.infos[i].playerID)});
                }
            }
            for (let i = 0; i < subsData.willInfos.length; i++) {
                if(subsData.willInfos[i].itemTitle == _subsItemList[selectSubsItemIndex])
                {
                    list.push({...subsData.willInfos[i], phone:getPlayerPhone(subsData.willInfos[i].playerID)});
                }
            }
        }
        else if(_state == 'payment1' || _state == 'payment2')
        {
            for (let i = 0; i < subsData.infos.length; i++) {
                if(subsData.infos[i].itemTitle == _subsItemList[selectSubsItemIndex])
                {
                    list.push({...subsData.infos[i], phone:getPlayerPhone(subsData.infos[i].playerID)});
                }
            }
        }
        else if(_state == 'will')
        {
            for (let i = 0; i < subsData.willInfos.length; i++) {
                if(subsData.willInfos[i].itemTitle == _subsItemList[selectSubsItemIndex])
                {
                    list.push({...subsData.willInfos[i], phone:getPlayerPhone(subsData.willInfos[i].playerID)});
                }
            }
        }
        else if(_state == 'firstPayment')
        {
            const startTime = parseInt(moment(month).startOf('month').unix());
            const endTime = parseInt(moment(month).endOf('month').unix());
            for (let i = 0; i < subsData.infos.length; i++) {
                let _registDate = new Date(subsData.infos[i].registTime * 1000)
                _registDate.setMonth(_registDate.getMonth() + 1)
                _registDate.setDate(_registDate.getDate() - 1)
                //console.log('aaaa : ', parseInt(_registDate.getTime()/1000), subsData.infos[i].endTime)
                const isFirst = (parseInt(_registDate.getTime()/1000) < subsData.infos[i].endTime) && (parseInt(_registDate.getTime()/1000) > subsData.infos[i].startTime)
                if(subsData.infos[i].itemTitle == _subsItemList[selectSubsItemIndex] && (isFirst))
                {
                    list.push({...subsData.infos[i], phone:getPlayerPhone(subsData.infos[i].playerID)});
                }
            }
        }
        else if(_state == 'morePayment')
        {
            const startTime = parseInt(moment(month).startOf('month').unix());
            const endTime = parseInt(moment(month).endOf('month').unix());
            for (let i = 0; i < subsData.infos.length; i++) {
                let _registDate = new Date(subsData.infos[i].registTime * 1000)
                _registDate.setMonth(_registDate.getMonth() + 1)
                _registDate.setDate(_registDate.getDate() - 1)
                //console.log('aaaa : ', parseInt(_registDate.getTime()/1000), subsData.infos[i].endTime)
                const isFirst = (parseInt(_registDate.getTime()/1000) < subsData.infos[i].endTime) && (parseInt(_registDate.getTime()/1000) > subsData.infos[i].startTime)
                if(subsData.infos[i].itemTitle == _subsItemList[selectSubsItemIndex] && !(isFirst))
                {
                    list.push({...subsData.infos[i], phone:getPlayerPhone(subsData.infos[i].playerID)});
                }
            }
        }
        else if(_state == 'totalcancel')
        {
            for (let i = 0; i < subsData.cancelInfos.length; i++) {
                if(subsData.cancelInfos[i].itemTitle == _subsItemList[selectSubsItemIndex])
                {
                    list.push({...subsData.cancelInfos[i], phone:getPlayerPhone(subsData.cancelInfos[i].playerID)});
                }
            }
        }
        else if(_state == 'paymentcancel')
        {    
            let today = parseInt(Date.now()/1000) 
            for (let i = 0; i < subsData.cancelInfos.length; i++) {
                if(subsData.cancelInfos[i].itemTitle == _subsItemList[selectSubsItemIndex] && subsData.cancelInfos[i].payTime <= today)
                {
                    list.push({...subsData.cancelInfos[i], phone:getPlayerPhone(subsData.cancelInfos[i].playerID)});
                }
            }
        }
        else if(_state == 'willcancel')
        {   
            let today = parseInt(Date.now()/1000)  
            for (let i = 0; i < subsData.cancelInfos.length; i++) {
                if(subsData.cancelInfos[i].itemTitle == _subsItemList[selectSubsItemIndex] && subsData.cancelInfos[i].payTime > today)
                {
                    list.push({...subsData.cancelInfos[i], phone:getPlayerPhone(subsData.cancelInfos[i].playerID)});
                }
            }
        }

        list.sort((a, b)=>{ return a.payTime - b.payTime})
        setSubsSelectTableData(_state)
        setSubsTableData(list)
    }

    const getOptionPrice = (option)=>{
        
        let optiondata = JSON.parse(option);

        let optionPrice = 0

        for (let j = 0; j < optiondata.length; j++) {
            optionPrice += optiondata[j].price
        }

        return optionPrice
    }

    const monthTime = new Date(moment(month).startOf('month').unix()*1000);
    return (
        <>
            <Wrapper>
                <DateControllBlock>
                    <DateInnerBlock>
                        <DateControllBtn
                            onClick={() => {
                                onLoadPrevMonth();
                            }}
                            className={'previous'}
                        >
                            <MdKeyboardArrowLeft />
                        </DateControllBtn>
                        <MonthBlock>
                            <InnerMonthBlock>{month}</InnerMonthBlock>
                            <InnerMonthBlock onClick={onToggleMonthPicker}>
                                <FiCalendar />
                            </InnerMonthBlock>
                            <MonthPicker
                                visible={monthPicker}
                                onChangeMonth={onChangeMonth}
                                date={month}
                                onToggleMonthPicker={onToggleMonthPicker}
                            />
                        </MonthBlock>
                        <DateControllBtn
                            onClick={() => {
                                onLoadNextMonth();
                            }}
                            className={'next'}
                        >
                            <MdKeyboardArrowRight />
                        </DateControllBtn>
                    </DateInnerBlock>
                </DateControllBlock>

                <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '40px' }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        {salesInfo && salesInfo.state === 1 &&
                            <>
                                <div onClick={() => { setMonthOption({ ...monthOption, showCounter: !monthOption.showCounter }) }} style={{ cursor: 'pointer', fontSize: '1.1rem', marginRight: '20px', display: 'flex', alignItems: 'center' }}><input type="checkbox" style={{ cursor: 'pointer', width: '17px', height: '17px', marginRight: '4px' }} checked={monthOption.showCounter} readOnly />현장 판매</div>
                                <div onClick={() => { setMonthOption({ ...monthOption, showMobile: !monthOption.showMobile }) }} style={{ cursor: 'pointer', fontSize: '1.1rem', display: 'flex', alignItems: 'center' }}><input type="checkbox" style={{ cursor: 'pointer', width: '17px', height: '17px', marginRight: '4px' }} checked={monthOption.showMobile} readOnly />모바일 판매</div>
                            </>
                        }
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <div onClick={() => { setMonthOption({ ...monthOption, disableNoneItem: !monthOption.disableNoneItem }) }} style={{ cursor: 'pointer', fontSize: '1.1rem', display: 'flex', alignItems: 'center' }}><input type="checkbox" style={{ cursor: 'pointer', width: '17px', height: '17px', marginRight: '4px' }} checked={monthOption.disableNoneItem} readOnly />판매 기록 없는 상품 숨기기</div>
                    </div>
                </div>
                <SummaryBlock style={{ marginTop: '10px' }}>
                    <GrayBox ratio={1} bigPadding isFlex>
                        <HalfWidthBlock>
                            <Chart dynamicValue={calcMonthlyStatistics()} />
                        </HalfWidthBlock>
                        <MonthlySummary>
                            {calcMonthlyStatistics().category.map((item, index) => (
                                <MonthlyCategory key={item.name}>
                                    <ChartLegend colorIndex={index} />
                                    <MonthlyText>{item.name}</MonthlyText>
                                    <MonthlySales>
                                        {inputNumberAutoComma(item.price)}원
                                    </MonthlySales>
                                </MonthlyCategory>
                            ))}
                        </MonthlySummary>
                    </GrayBox>
                    <GrayBox ratio={3} nonePadding>
                        <CategoryWrapper>
                            <MonthlyTable goods={goods} items={customItem} memberships={monthlyTableData} monthOption={monthOption} selectedDay={month} />
                        </CategoryWrapper>
                    </GrayBox>
                </SummaryBlock>
                <PaymentBlock>
                    <PaymentSummaryLine>
                        <PaymentText>카드</PaymentText>
                        <PaymentText>
                            {inputNumberAutoComma(calcMonthlyStatistics().card)}원
                        </PaymentText>
                    </PaymentSummaryLine>
                    <PaymentSummaryLine>
                        <PaymentText>현금</PaymentText>
                        <PaymentText>
                            {inputNumberAutoComma(calcMonthlyStatistics().cash)}원
                        </PaymentText>
                    </PaymentSummaryLine>
                    <PaymentSummaryLine>
                        <PaymentText>포인트</PaymentText>
                        <PaymentText>
                            {inputNumberAutoComma(calcMonthlyStatistics().point)}원
                        </PaymentText>
                    </PaymentSummaryLine>
                    <PaymentSummaryLine>
                        <PaymentText>페이</PaymentText>
                        <PaymentText>
                            {inputNumberAutoComma(calcMonthlyStatistics().pay)}원
                        </PaymentText>
                    </PaymentSummaryLine>
                    <PaymentSummaryLine>
                        <PaymentText>미수금</PaymentText>
                        <PaymentText>
                            {inputNumberAutoComma(calcMonthlyStatistics().unpayment)}원
                        </PaymentText>
                    </PaymentSummaryLine>
                    <PaymentSummaryLine>
                        <PaymentText emphasis>총 매출</PaymentText>
                        <PaymentText emphasis>
                            {inputNumberAutoComma(calcMonthlyStatistics().sales)}원
                        </PaymentText>
                        <TotalRefund>
                            <div className="sub">환불 금액</div>
                            <div>
                                {inputNumberAutoComma(refundTotal)}원
                            </div>
                        </TotalRefund>
                    </PaymentSummaryLine>
                </PaymentBlock>
                <CalendarBlock>
                    <TabBlock style={{ justifyContent: 'space-between' }}>
                        <div style={{ display: 'flex' }}>
                            <TabText
                                onClick={() => onSetTab('calendar')}
                                active={active === 'calendar'}
                            >
                                일별 매출
                            </TabText>
                            <TabText
                                onClick={() => onSetTab('table')}
                                active={active === 'table'}
                            >
                                상세 매출
                            </TabText>
                            <TabText
                                onClick={() => onSetTab('refund')}
                                active={active === 'refund'}
                            >
                                환불 기록
                            </TabText>
                            {
                                salesInfo && (salesInfo.state === 1 || salesInfo.state === 2) &&
                                <TabText
                                    onClick={() => onSetTab('mobileHistory')}
                                    active={active === 'mobileHistory'}
                                >
                                    모바일 결제 내역
                                </TabText>
                            }
                            {
                                isSubsUse() &&
                                <TabText
                                    onClick={() =>{
                                        onSetTab('subscriptionCalculate');
                                        resetSubsItemList()
                                    }}
                                    active={active === 'subscriptionCalculate'}
                                >
                                    구독자 정보
                                </TabText>
                            }
                            {
                                salesInfo && (salesInfo.state === 1 || salesInfo.state === 2) &&
                                <TabText
                                    onClick={() => onSetTab('mobileCalculrate')}
                                    active={active === 'mobileCalculrate'}
                                >
                                    모바일 매출 정산
                                </TabText>
                            }
                        </div>
                    </TabBlock>

                    {salesInfo && salesInfo.state === 1 && active !== 'mobileHistory' && active !== 'mobileCalculrate'  && active !== 'subscriptionCalculate' &&
                        <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-start', alignItems: 'center', marginTop: '40px' }}>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <div onClick={() => { setDayOption({ ...dayOption, showCounter: !dayOption.showCounter }) }} style={{ cursor: 'pointer', fontSize: '1.1rem', marginRight: '20px', display: 'flex', alignItems: 'center' }}><input type="checkbox" style={{ cursor: 'pointer', width: '17px', height: '17px', marginRight: '4px' }} checked={dayOption.showCounter} readOnly />현장 판매</div>
                                <div onClick={() => { setDayOption({ ...dayOption, showMobile: !dayOption.showMobile }) }} style={{ cursor: 'pointer', fontSize: '1.1rem', display: 'flex', alignItems: 'center' }}><input type="checkbox" style={{ cursor: 'pointer', width: '17px', height: '17px', marginRight: '4px' }} checked={dayOption.showMobile} readOnly />모바일 판매</div>
                            </div>
                        </div>
                    }

                    {active === 'calendar' ? (
                        <Calendar onClickDate={onClickDate} data={dailyCalendarData} date={month} items={items} salesByDate={salesByDate} />
                    ) : ""
                    }

                    {active === 'table' ? (
                        <>
                            <BtnBlock>
                                <Button
                                    onClick={() => download(month)}
                                >{`Excel 다운로드`}</Button>
                            </BtnBlock>
                            <SalesTable tableData={dailyTableData} salesInfo={salesInfo} />
                        </>
                    ) : ""
                    }

                    {active === 'refund' ? (
                        <>
                            <BtnBlock>
                                <Button
                                    onClick={() => download(month)}
                                >{`Excel 다운로드`}</Button>
                            </BtnBlock>
                            <RefundTable tableData={dailyRefundData} month={month} salesInfo={salesInfo} />
                        </>
                    ) : ""}
                    {active === 'mobileHistory' ? (
                        <>
                            {
                                isSubs()?
                                <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '40px' }}>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <>
                                            <div onClick={() => { setMobileOption({ ...mobileOption, showMembership: !mobileOption.showMembership }) }} style={{ cursor: 'pointer', fontSize: '1.1rem', marginRight: '20px', display: 'flex', alignItems: 'center' }}><input type="checkbox" style={{ cursor: 'pointer', width: '17px', height: '17px', marginRight: '4px' }} checked={mobileOption.showMembership} readOnly />회원권</div>
                                            <div onClick={() => { setMobileOption({ ...mobileOption, showSubscription: !mobileOption.showSubscription }) }} style={{ cursor: 'pointer', fontSize: '1.1rem', display: 'flex', alignItems: 'center' }}><input type="checkbox" style={{ cursor: 'pointer', width: '17px', height: '17px', marginRight: '4px' }} checked={mobileOption.showSubscription} readOnly />구독</div>
                                        </>
                                    </div>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                    </div>
                                </div>
                                :null
                            }
                            <BtnBlock>
                                <button ref={wrapperRef} onClick={() => setCalculateModal(true)} style={{ minWidth: '65px', border: '1px solid rgba(0, 0, 0, .2)', backgroundColor: "#fff", borderRadius: '4px', marginRight: '14px', padding: '.3rem 1rem', cursor: 'pointer', position: 'relative' }}>
                                    {calculateModal &&
                                        <div style={{ boxShadow: '0px 5px 10px 0px rgba(0, 0, 0, 0.1)', display: 'flex', flexDirection: 'column', borderRadius: '6px', overflow: 'hidden', border: `1px solid ${palette.webCyan[5]}`, cursor: 'default', fontSize: '1rem', position: 'absolute', right: '110%', bottom: 0, width: '1000px', textAlign: 'center' }}>
                                            <table style={{ backgroundColor: '#fff' }}>
                                                <thead style={{ backgroundColor: '#468AE3', color: '#fff' }}>
                                                    <tr>
                                                        <td>판매일</td>
                                                        <td>정산 예정일</td>
                                                        <td>카드</td>
                                                        <td>포인트</td>
                                                        {/* <td>결제 수수료(4.5%)</td> */}
                                                        <td>정산 금액</td>
                                                    </tr>
                                                </thead>
                                                <tbody style={{ fontSize: '.9rem' }}>
                                                    {
                                                        calculateData && calculateData.map((data, idx) => {
                                                            return (
                                                                <tr key={idx} style={{ borderBottom: '1px solid #E1E1E1' }}>
                                                                    <td>{data.registTime}</td>
                                                                    <td>{data.calculateTime}</td>
                                                                    <td>{inputNumberAutoComma(data.card)}원</td>
                                                                    <td>{inputNumberAutoComma(data.point)}원</td>
                                                                    {/* <td>{inputNumberAutoComma(data.fees)}원</td> */}
                                                                    <td style={{ fontWeight: 'bold' }}>{inputNumberAutoComma(data.calculatePrice)}원</td>
                                                                </tr>
                                                            )
                                                        })
                                                    }
                                                </tbody>
                                            </table>
                                            <div style={{ backgroundColor: '#fff', padding: '6px 10px', width: '100%', textAlign: 'right', color: palette.webCyan[5], fontSize: '.9rem' }}>* 정산 예정일이 휴일이라면 해당 휴일이 지난 이후의 평일에 정산 금액이 지급됩니다.</div>
                                        </div>
                                    }
                                    정산 내역
                                </button>
                                <Button
                                    onClick={() => download(month)}
                                >{`Excel 다운로드`}</Button>
                            </BtnBlock>
                            <MobileHistoryTable tableData={dailyMobileHistoryData} month={month} salesInfo={salesInfo} onClickCancelPayment={onClickCancelPayment} onClickCancelPaymentAdmin={onClickCancelPaymentAdmin} mobileOption={mobileOption}/>
                        </>
                    ) : ""}
                    {
                        active === 'subscriptionCalculate' ? (
                            <>
                                <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-start', alignItems: 'center', marginTop: '40px' }}>
                                    {
                                        subsItemList.map((subsitem, subsindex)=>{
                                            return (
                                                <div
                                                    style={{ display: 'flex', alignItems: 'center' }}
                                                    key={`sales_subsItemList_${subsindex}`}
                                                >
                                                    <div
                                                        onClick={() => { setSelectSubsItemIndex(subsindex) }}
                                                        style={{color:subsindex == selectSubsItemIndex?palette.webCyan[5]:'#000', cursor: 'pointer', fontSize: '1.1rem', display: 'flex', alignItems: 'center' }}
                                                    >
                                                        {subsitem}
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                                {
                                    subsItemList.length > 0 && subsData?
                                    <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-start', alignItems: 'center', marginTop: '40px' }}>
                                        <div style={{ width: '27%', borderRadius:10, height:120, backgroundColor:'#EEF0F3', marginRight:'7%', padding:'10px 10px 10px 10px'}}>
                                            <div
                                                style={{color:subsSelectTableData == 'total'?palette.webCyan[5]:'#000', cursor: 'pointer', padding:'3px 3px 3px 3px', display:'flex'}}
                                                onClick={()=>setTableData('total', subsItemList)}
                                            >
                                                {monthTime.getMonth()+1}월 구독자 수
                                                <div style={{flex:1}}/>
                                                {`${getTotalSubsCount()}명`}
                                            </div>
                                            <div style={{width:'100%', height:'1px', backgroundColor:'#000', margin:'10px 10px 10px 0px'}}/>
                                            <div
                                                style={{color:subsSelectTableData == 'payment1'?palette.webCyan[5]:'#000', cursor: 'pointer', padding:'3px 3px 3px 3px', paddingLeft:'10px', display:'flex'}}
                                                onClick={()=>setTableData('payment1', subsItemList)}
                                            >
                                                결제 완료
                                                <div style={{flex:1}}/>
                                                {`${getPaymentSubsCount()}명`}
                                            </div>
                                            <div
                                                style={{color:subsSelectTableData == 'will'?palette.webCyan[5]:'#000', cursor: 'pointer', padding:'3px 3px 3px 3px', paddingLeft:'10px', display:'flex'}}
                                                onClick={()=>setTableData('will', subsItemList)}
                                            >
                                                결제 예정
                                                <div style={{flex:1}}/>
                                                {`${getwillSubsCount()}명`}
                                            </div>
                                        </div>
                                        <div style={{ width: '27%', borderRadius:10, height:120, backgroundColor:'#EEF0F3', marginRight:'7%', padding:'10px 10px 10px 10px'}}>
                                            <div
                                                style={{color:subsSelectTableData == 'payment2'?palette.webCyan[5]:'#000', cursor: 'pointer', padding:'3px 3px 3px 3px', display:'flex'}}
                                                onClick={()=>setTableData('payment2', subsItemList)}
                                            >
                                                {monthTime.getMonth()+1}월 구독결제 완료
                                                <div style={{flex:1}}/>
                                                {`${getPaymentSubsCount()}명`}
                                            </div>
                                            <div style={{width:'100%', height:'1px', backgroundColor:'#000', margin:'10px 10px 10px 0px'}}/>
                                            <div
                                                style={{color:subsSelectTableData == 'morePayment'?palette.webCyan[5]:'#000', cursor: 'pointer', padding:'3px 3px 3px 3px', paddingLeft:'10px', display:'flex'}}
                                                onClick={()=>setTableData('morePayment', subsItemList)}
                                            >
                                                기존 구독 결제
                                                <div style={{flex:1}}/>
                                                {`${getPaymentMoreSubsCount()}명`}
                                            </div>
                                            <div
                                                style={{color:subsSelectTableData == 'firstPayment'?palette.webCyan[5]:'#000', cursor: 'pointer', padding:'3px 3px 3px 3px', paddingLeft:'10px', display:'flex'}}
                                                onClick={()=>setTableData('firstPayment', subsItemList)}
                                            >
                                                최초 구독 결제
                                                <div style={{flex:1}}/>
                                                {`${getPaymentFirstSubsCount()}명`}
                                            </div>
                                        </div>
                                        <div style={{ width: '27%', borderRadius:10, height:120, backgroundColor:'#EEF0F3', marginRight:'7%', padding:'10px 10px 10px 10px'}}>
                                            <div
                                                style={{color:subsSelectTableData == 'totalcancel'?palette.webCyan[5]:'#000', cursor: 'pointer', padding:'3px 3px 3px 3px', display:'flex'}}
                                                onClick={()=>setTableData('totalcancel', subsItemList)}
                                            >
                                                {monthTime.getMonth()+1}월 구독 취소자 수
                                                <div style={{flex:1}}/>
                                                {`${getCancelSubsTotalCount()}명`}
                                            </div>
                                            <div style={{width:'100%', height:'1px', backgroundColor:'#000', margin:'10px 10px 10px 0px'}}/>
                                            <div
                                                style={{color:subsSelectTableData == 'paymentcancel'?palette.webCyan[5]:'#000', cursor: 'pointer', padding:'3px 3px 3px 3px', paddingLeft:'10px', display:'flex'}}
                                                onClick={()=>setTableData('paymentcancel', subsItemList)}
                                            >
                                                취소 완료
                                                <div style={{flex:1}}/>
                                                {`${getCancelSubsCount()}명`}
                                            </div>
                                            <div
                                                style={{color:subsSelectTableData == 'willcancel'?palette.webCyan[5]:'#000', cursor: 'pointer', padding:'3px 3px 3px 3px', paddingLeft:'10px', display:'flex'}}
                                                onClick={()=>setTableData('willcancel', subsItemList)}
                                            >
                                                취소 예정
                                                <div style={{flex:1}}/>
                                                {`${getWillCancelSubsCount()}명`}
                                            </div>
                                            {/* <div
                                                style={{color:subsSelectTableData == 'cancel'?palette.webCyan[5]:'#000', cursor: 'pointer', padding:'3px 3px 3px 3px', display:'flex'}}
                                                onClick={()=>setTableData('cancel'), subsItemList}
                                            >
                                                {monthTime.getMonth()+1}월 구독취소 완료
                                                <div style={{flex:1}}/>
                                                {`${getCancelSubsCount()}명`}
                                            </div> */}
                                        </div>
                                    </div>
                                    :null
                                }
                                <>
                                    {
                                        subsTableData && subsTableData.length > 0?
                                        <div style={{marginTop: '40px' }}>
                                            <BtnBlock>
                                                <Button
                                                    onClick={() => download(month)}
                                                >{`Excel 다운로드`}</Button>
                                            </BtnBlock>
                                            <Table className="sales_details">
                                                <thead>
                                                    <tr>
                                                        <td>회원명</td>
                                                        <td>전화 번호</td>
                                                        <td>상품명</td>
                                                        <td>{(subsSelectTableData == 'totalcancel' || subsSelectTableData == 'paymentcancel' || subsSelectTableData == 'willcancel')?'취소일':'결제일'}</td>
                                                        <td>기간</td>
                                                        <td>판매 금액</td>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        subsTableData && subsTableData.map((data, idx) => {
                                                            return (
                                                                <tr key={idx} style={{ borderBottom: '1px solid #E1E1E1' }}>
                                                                    <td>{data.playerName}</td>
                                                                    <td>{data.phone}</td>
                                                                    <td>{data.itemTitle}</td>
                                                                    <td>{timeValueToDate(data.payTime)}</td>
                                                                    <td>{(subsSelectTableData == 'totalcancel' || subsSelectTableData == 'paymentcancel' || subsSelectTableData == 'willcancel')?'-':`${timeValueToDate(data.startTime)} ~ ${timeValueToDate(data.endTime)}`}</td>
                                                                    <td>{inputNumberAutoComma(data.price + getOptionPrice(data.option))}원</td>
                                                                </tr>
                                                            )
                                                        })
                                                    }
                                                </tbody>
                                            </Table>
                                        </div>
                                        :<div style={{height: '100px' }}/>
                                    }
                                </>
                            </>
                        ):null
                    }
                    {active === 'mobileCalculrate' ? (
                        <>
                            <BtnBlock>
                                <Button
                                    onClick={() => download(month)}
                                >{`Excel 다운로드`}</Button>
                            </BtnBlock>
                            <Table className="sales_details">
                                <thead>
                                    <tr>
                                        <td>판매일</td>
                                        <td>정산 예정일</td>
                                        <td>카드</td>
                                        <td>포인트</td>
                                        {/* <td>결제 수수료(4.5%)</td> */}
                                        <td>정산 금액</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        calculateData && calculateData.map((data, idx) => {
                                            return (
                                                <tr key={idx} style={{ borderBottom: '1px solid #E1E1E1' }}>
                                                    <td>{data.registTime}</td>
                                                    <td>{data.calculateTime}</td>
                                                    <td>{inputNumberAutoComma(data.card)}원</td>
                                                    <td>{inputNumberAutoComma(data.point)}원</td>
                                                    {/* <td>{inputNumberAutoComma(data.fees)}원</td> */}
                                                    <td style={{ fontWeight: 'bold' }}>{inputNumberAutoComma(data.calculatePrice)}원</td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </Table>
                        </>
                    ) : ""}
                </CalendarBlock>
            </Wrapper>
        </>
    );
};

export default Sales;
